import React, { useState, useEffect, useCallback } from "react";
import { Col, Row, Card } from "react-bootstrap";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { addSettingsData, setTimeZone } from "../../store/settings/action";
import timeZone from "./timeZones.json";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Box,
  TextField,
  Tooltip,
} from "@mui/material";
import { MenuHeight, VisuallyHiddenInput } from "../NewDashboard/constant";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import common, { convertMBToBytes } from "../../Components/Common/common";
import { Spinner } from "reactstrap";
import { useDefaultImageSize } from "../../Components/Hooks/UseDeafultImageSizeHook";
import { getAuthToken } from "../../Api/common";

const Other = () => {
  const token = getAuthToken();
  const dispatch = useDispatch();
  const DEFAULT_IMAGE_SIZE = useDefaultImageSize();

  const [isLoading, setIsLoading] = useState(false);
  const intialValues = {
    DEFAULT_DATE_FORMAT: "",
    DEFAULT_TIME_FORMAT: "",
    DEFAULT_TIME_ZONE: "",
    GUIDELINE_PDF_IMAGE: null,
    DEFAULT_IMAGE_SIZE: "",
    DEFAULT_PDF_SIZE: "",
  };

  const timeZones = timeZone.map((timezone) => ({
    label: timezone.text,
    value: timezone.utc[0],
  }));

  const navigate = useNavigate();
  const values = intialValues;

  const options = {
    defaultDateFormat: [
      { label: "d/m/Y (Eg: 01/03/2023)", value: "d/m/Y" },
      { label: "m/d/Y (Eg: 03/01/2023)", value: "m/d/Y" },
      { label: "Y/m/d (Eg: 2023/03/01)", value: "Y/m/d" },
      { label: "Y/d/m (Eg: 2023/01/03)", value: "Y/d/m" },
      { label: "M/d/Y (Eg: Mar/01/2023)", value: "M/d/Y" },
      { label: "M d Y (Eg: Mar 01 2023)", value: "M d Y" },
    ],
    timeFormat: [
      { label: "12 Hours", value: "12" },
      { label: "24 Hours", value: "24" },
    ],
    timezone: timeZones,
  };

  const PostData = async ({
    DEFAULT_DATE_FORMAT,
    DEFAULT_TIME_FORMAT,
    DEFAULT_TIME_ZONE,
    GUIDELINE_PDF_IMAGE,
    DEFAULT_IMAGE_SIZE,
    DEFAULT_PDF_SIZE,
  }) => {
    try {
      setIsLoading(true);
      let url = `${process.env.REACT_APP_BASE_URL}settings/add-settings`;

      let formData = new FormData();
      formData.append("DEFAULT_DATE_FORMAT", DEFAULT_DATE_FORMAT);
      formData.append("DEFAULT_TIME_FORMAT", DEFAULT_TIME_FORMAT);
      formData.append("DEFAULT_TIME_ZONE", DEFAULT_TIME_ZONE);
      formData.append("GUIDELINE_PDF_IMAGE", GUIDELINE_PDF_IMAGE);
      formData.append("DEFAULT_IMAGE_SIZE", DEFAULT_IMAGE_SIZE);
      formData.append("DEFAULT_PDF_SIZE", DEFAULT_PDF_SIZE);

      let reqData = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      };

      let result = await fetch(url, reqData);
      let response = await result.json();

      if (response.code === 403) {
        navigate("/auth-404-cover");
      }
      if (response.code === "200") {
        toast.success(response.message);
        getSettings();
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred while processing your request.");
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: values,

    onSubmit: async (values, { setSubmitting }) => {
      dispatch(setTimeZone(values.DEFAULT_TIME_ZONE));
      try {
        await PostData(values);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const getSettings = useCallback(async () => {
    try {
      let url = `${process.env.REACT_APP_BASE_URL}settings/get-settings`;
      let reqData = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let result = await fetch(url, reqData);
      let response = await result.json();

      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;

      if (response.code === 403) {
        navigate("/auth-404-cover");
      }

      if (response.code === "200") {
        const fieldValuesMap = {};
        response.data.rows.forEach((row) => {
          fieldValuesMap[row.field_name] = row.field_value;
        });

        const settingData = response?.data?.rows;
        dispatch(addSettingsData(settingData));

        formik.setValues({
          ...formik.values,
          DEFAULT_DATE_FORMAT: fieldValuesMap["DEFAULT_DATE_FORMAT"] || "",
          DEFAULT_TIME_FORMAT: fieldValuesMap["DEFAULT_TIME_FORMAT"] || "",
          DEFAULT_TIME_ZONE: fieldValuesMap["DEFAULT_TIME_ZONE"] || "",
          GUIDELINE_PDF_IMAGE: fieldValuesMap["GUIDELINE_PDF_IMAGE"] || "",
          DEFAULT_IMAGE_SIZE: fieldValuesMap["DEFAULT_IMAGE_SIZE"] || "",
          DEFAULT_PDF_SIZE: fieldValuesMap["DEFAULT_PDF_SIZE"] || "",
        });
        if (fieldValuesMap["GUIDELINE_PDF_IMAGE"]) {
          const logoUrl = `${process.env.REACT_APP_BASE_URL}${fieldValuesMap["GUIDELINE_PDF_IMAGE"]}`;
          setUploadedFile(logoUrl);
        }
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, token]);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  const handleFieldChange = (field, value) => {
    formik.setFieldValue(field, value);
  };

  const handleFieldTouched = (field) => formik.setFieldTouched(field, true);
  const [uploadedFile, setUploadedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedFormats = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      const validSize = file.size <= convertMBToBytes(DEFAULT_IMAGE_SIZE);

      if (!allowedFormats.includes(file.type)) {
        toast.error(
          "Invalid file format. Only JPEG, JPG, PNG, and SVG files are allowed."
        );
      } else if (!validSize) {
        toast.error(
          `Failed to upload an image. The image maximum size is ${DEFAULT_IMAGE_SIZE} MB.`
        );
      } else {
        // Set formik field value
        formik.setFieldValue("GUIDELINE_PDF_IMAGE", file);

        // Read file as data URL
        const reader = new FileReader();
        reader.onload = (e) => {
          setUploadedFile(e.target.result); // Assuming setUploadedFile sets the uploaded file as a data URL
        };
        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <React.Fragment>
      <Card>
        <Card.Body className="setting-card-body">
          <form noValidate onSubmit={formik.handleSubmit}>
            <Row className="g-4">
              <Col md={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="time-zone-select">Time Zone</InputLabel>
                  <Select
                    fullWidth
                    label="Time Zone"
                    MenuProps={MenuHeight}
                    id="time-zone-select"
                    value={formik.values.DEFAULT_TIME_ZONE}
                    onChange={(e) =>
                      handleFieldChange("DEFAULT_TIME_ZONE", e.target.value)
                    }
                    onBlur={() => handleFieldTouched("DEFAULT_TIME_ZONE")}
                    placeholder="Select TimeZone"
                  >
                    {options?.timezone?.map((option, idx) => (
                      <MenuItem key={idx} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>

              <Col md={6}>
                <FormControl fullWidth>
                  <InputLabel>Default Date Format (d/m/Y)</InputLabel>
                  <Select
                    fullWidth
                    label="Default Date Format (d/m/Y)"
                    MenuProps={MenuHeight}
                    value={formik.values.DEFAULT_DATE_FORMAT}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "DEFAULT_DATE_FORMAT",
                        event.target.value
                      );
                    }}
                    onBlur={() =>
                      formik.setFieldTouched("DEFAULT_DATE_FORMAT", true)
                    }
                  >
                    <MenuItem disabled value="select">
                      select
                    </MenuItem>
                    {options.defaultDateFormat.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>

              <Col md={6}>
                <FormControl fullWidth>
                  <InputLabel>Default Time Format</InputLabel>
                  <Select
                    fullWidth
                    label="Default Time Format"
                    MenuProps={MenuHeight}
                    value={formik.values.DEFAULT_TIME_FORMAT}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "DEFAULT_TIME_FORMAT",
                        event.target.value
                      );
                    }}
                    onBlur={() =>
                      formik.setFieldTouched("DEFAULT_TIME_FORMAT", true)
                    }
                  >
                    <MenuItem disabled value="select">
                      select
                    </MenuItem>
                    {options.timeFormat.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>

              <Col md={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  <Button
                    component="label"
                    role={undefined}
                    className="btn btn-outline-primary"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                  >
                    Default Image
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                  <Tooltip
                    title={
                      <>
                        This is the default image displayed when an image is not
                        available in the Website & Branding Guidelines.
                      </>
                    }
                    placement="top-start"
                    arrow
                  >
                    <i
                      className="ri-information-line fs-18 cursor-pointer ms-2"
                      style={{
                        color: "#ced4da",
                      }}
                    ></i>
                  </Tooltip>
                  {uploadedFile && (
                    <Box
                      sx={{
                        background: "#f8f8f8",
                        padding: "5px",
                        width: "150px",
                        height: "110px",
                      }}
                    >
                      <img
                        src={uploadedFile}
                        alt="Uploaded"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Col>

              <Col md={6}>
                <TextField
                  fullWidth
                  label="Default Image Size (MB)"
                  type="number"
                  value={formik.values.DEFAULT_IMAGE_SIZE || ""}
                  onChange={(e) =>
                    handleFieldChange("DEFAULT_IMAGE_SIZE", e.target.value)
                  }
                  onBlur={() => handleFieldTouched("DEFAULT_IMAGE_SIZE")}
                  placeholder="Enter default image size in MB"
                  inputProps={{ min: 0 }} // Prevent negative values
                />
              </Col>

              <Col md={6}>
                <TextField
                  fullWidth
                  label="Default PDF Size (MB)"
                  type="number"
                  value={formik.values.DEFAULT_PDF_SIZE || ""}
                  onChange={(e) =>
                    handleFieldChange("DEFAULT_PDF_SIZE", e.target.value)
                  }
                  onBlur={() => handleFieldTouched("DEFAULT_PDF_SIZE")}
                  placeholder="Enter default PDF size in MB"
                  inputProps={{ min: 0 }} // Prevent negative values
                />
              </Col>

              <Col sm={12}>
                <Button
                  className="btn btn-primary ms-auto d-block"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner size="sm" variant="light" />
                  ) : (
                    <>Save &amp; Keep Editing</>
                  )}
                </Button>
              </Col>
            </Row>
          </form>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default Other;
