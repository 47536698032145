import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";

import { ToastMessage } from "./ToastMessage";

//SimpleBar

// import { socket } from "../../socket";
import { socket } from "../../Components/Common/socket";
import { useDispatch, useSelector } from "react-redux";
import { initList, resetInitList } from "../../store/notification/action";

import { Badge, Box, IconButton, Menu, Typography } from "@mui/material";
import { formatDateTime } from "./FormatDateTIme";
import common from "./common";
import axios from "axios";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getAuthToken, getUserId } from "../../Api/common";
const ITEM_HEIGHT = 48;

const NotificationDropdown = () => {
  // const ENDPOINT = process.env.REACT_APP_BASE_URL;

  const diapatch = useDispatch();
  const navigate = useNavigate();

  //Dropdown Toggle
  const [notificationCount, setNotificationCount] = useState(0);
  const [newNotificationFlag, setNewNotificationFlag] = useState("");

  const [updateFirstFiveNotifications, setUpdateFirstFiveNotifications] =
    useState(false);

  const loginUser = getUserId();
 
  const { notificationList, unreadCount } = useSelector(
    (state) => state.notification
  );

  //Tab
  const [perPage, setPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    socket.emit("setup");
    socket.on("connected", (message) => {});

    socket.on("updateAnnouncement", (message) => {
      getData();
    });

    socket.on("addAnnouncement", (message) => {
      getData();
    });

    socket.on("deleteAnnouncement", (message) => {
      getData();
    });

    socket.on("addUser", (message) => {
      getData();
    });

    socket.on("addCoreUser", (message) => {
      getData();
    });

    socket.on("addInternalUser", (message) => {
      getData();
    });

    socket.on("updateUser", (message) => {
      getData();
    });

    socket.on("updateCoreUser", (message) => {
      getData();
    });

    socket.on("updateInternalUser", (message) => {
      getData();
    });

    socket.on("deleteUser", (message) => {
      getData();
    });

    socket.on("deleteCoreUser", (message) => {
      getData();
    });

    socket.on("deleteInternalUser", (message) => {
      getData();
    });

    socket.on("addBrandingGuideline", (message) => {
      getData();
    });

    socket.on("updateBrandingGuideline", (message) => {
      getData();
    });

    socket.on("deleteBrandingGuideline", (message) => {
      getData();
    });

    socket.on("addWebsiteGuideline", (message) => {
      getData();
    });

    socket.on("updateWebsiteGuideline", (message) => {
      getData();
    });

    socket.on("deleteWebsiteGuideline", (message) => {
      getData();
    });

    socket.on("addOnlineAsset", (message) => {
      getData();
    });

    socket.on("updateOnlineAsset", (message) => {
      getData();
    });

    socket.on("addNote", (message) => {
      getData();
    });

    socket.on("websecurityscan", (message) => {
      getData();
    });

    socket.on("sendwebsecurityreport", (message) => {
      getData();
    });

    socket.on("assetattachment", (message) => {
      getData();
    });

    socket.on("policy", (message) => {
      getData();
    });

    socket.on("addTicketOnlineAsset", (message) => {
      getData();
    });

    socket.on("addTicket", (message) => {
      getData();
    });

    socket.on("updateTicketStatus", (message) => {
      getData();
    });

    socket.on("updateTicketPriority", (message) => {
      getData();
    });

    socket.on("assignTicket", (message) => {
      getData();
    });

    socket.on("addDeveloperProfile", (message) => {
      getData();
    });

    socket.on("updateDeveloperProfile", (message) => {
      getData();
    });

    socket.on("deleteDeveloperProfile", (message) => {
      getData();
    });

    return () => {
      socket.off("disconnect");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;

  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    const authToken = getAuthToken();
    setUpdateFirstFiveNotifications(false);
    let reqData = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    let result = await fetch(
      `${process.env.REACT_APP_BASE_URL}notifications/get-notification?userId=${loginUser}&page=${currentPage}&limit=${perPage}`,
      reqData
    );

    let response = await result.json();

    if (response.status === "success") {
      diapatch(resetInitList());
      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;
      setNotificationCount(response.data.count);

      let finalResult = {
        count: response.data.count,
        rows: response.data?.rows,
        unreadCount: response.data.unreadCount,
      };

      diapatch(initList(finalResult));
    }

    if (response.status === "fail") {
      ToastMessage.Error(response.message);
    }
  };

  const getAllNewNotifications = async (perPageSize) => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}notifications/all-new-notifications?userId=${loginUser}&page=${currentPage}&limit=${perPageSize}`
      );

      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;

      if (response.status === "success") {
        diapatch(
          initList({
            count: notificationCount,
            rows: [...notificationList, ...response.data?.result],
            unreadCount: response.data?.unreadCount,
          })
        );
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleTheFirstFiveNotificationUpdate = async () => {
    try {
      await axios.get(
        `${process.env.REACT_APP_BASE_URL}notifications/get-notification?userId=${loginUser}&page=${currentPage}&limit=${perPage}`
      );

      let response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}notifications/get-updatenotificationlist?userId=${loginUser}&limit=${perPage}&once=true`
      );

      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;

      if (response.status === "success") {
        diapatch(
          initList({
            count: notificationCount,
            rows: notificationList,
            unreadCount: response.data?.unreadCount,
          })
        );
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const getDataUpdatenotificationlist = async (currentPage, perPageSize) => {
    const authToken = getAuthToken();
    let reqData = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    let result;
    if (newNotificationFlag === "") {
      if (updateFirstFiveNotifications) {
        await fetch(
          `${process.env.REACT_APP_BASE_URL}notifications/get-updatenotificationlist?userId=${loginUser}&page=${currentPage}&limit=${perPageSize}`,
          reqData
        );
      }

      result = await fetch(
        `${process.env.REACT_APP_BASE_URL}notifications/get-notification?userId=${loginUser}&page=${currentPage}&limit=${perPageSize}&requestMessage=${newNotificationFlag}`,
        reqData
      );

      let response = await result.json();
      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;
      if (response.status === "success") {
        setNotificationCount(response.data.count);
        let finalResult = {
          count: response.data.count,
          rows: [...notificationList, ...response.data.rows],
          unreadCount: response.data.unreadCount,
        };
        diapatch(initList(finalResult));
      }
      if (response.status === "fail") {
        ToastMessage.Error(response.message);
      }
    } else {
      await getAllNewNotifications(perPageSize);
    }
  };

  useEffect(() => {
    getDataUpdatenotificationlist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newNotificationFlag]);

  const handleLoadMore = async () => {
    setCurrentPage((prev) => prev + 1);
    setCurrentPage((prev) => {
      const newPage = prev;
      getDataUpdatenotificationlist(newPage, perPage);
      return newPage;
    });
  };

  const handleViewAllNotification = () => {
    setAnchorEl(null);
    navigate("/notifications", {
      state: {
        notificationList: notificationList,
      },
    });
  };

  const handleAllNewNotification = async () => {
    setNewNotificationFlag("unread");
    setPerPage(5);
    setCurrentPage(1);
    diapatch(resetInitList());
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (!updateFirstFiveNotifications) {
      setUpdateFirstFiveNotifications(true);
      handleTheFirstFiveNotificationUpdate();
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (path, id, user) => {
    if (!id) {
      return;
    }
    if (
      path === "/ticketing-system" ||
      path === "/developer-profile" ||
      path === "/uptime-report"
    ) {
      navigate(path + "/" + id);
    } else if (path === "/online-assets") {
      if (user?.txt_notification.includes("security scan")) {
        navigate(path + "/" + id, {
          state: {
            tabValueFor: "securityTab",
          },
        });
      }
      //This below else - if the txt_notificaton is releated to attachment then the below block (else) is created
      else {
        navigate(path + "/" + id, {
          state: {
            tabValueFor: "attachmentTab",
          },
        });
      }
    } else {
      navigate(path);
    }
  };

  const handleReadAllNotifications = async () => {
    try {
      setLoading(true);

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}notifications/readAll-notifications`,
        {
          userId: loginUser,
        }
      );
      if (response.status === "success") {
        getData();
      } else {
        console.error("Failed to mark notifications as read");
      }
    } catch (error) {
      console.error("Error marking notifications as read:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Box>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <Badge
            badgeContent={unreadCount}
            sx={{ "& .MuiBadge-badge": { background: "red", color: "#fff" } }}
          >
            <i className="bx bx-bell fs-22 text-black"></i>
          </Badge>
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "50ch",
              borderRadius: "8px",
              boxShadow: "0 0 17px 0 rgba(0, 0, 0, 0.08)",
            },
          }}
          sx={{
            "& .MuiList-root": {
              padding: 0,
            },
          }}
        >
          <Box className="dropdown-head bg-primary bg-pattern rounded-top">
            <Box className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 fs-16 fw-semibold text-white">
                    Notifications
                  </h6>
                </Col>
                <Box className="col-auto dropdown-tabs">
                  <span
                    className="badge badge-soft-light fs-13 cursor-pointer"
                    onClick={() => {
                      if (newNotificationFlag === "" && unreadCount !== 0)
                        handleAllNewNotification();
                    }}
                  >
                    {`${unreadCount} Unread`}
                  </span>
                </Box>
                <Box className="col-auto dropdown-tabs">
                  <span
                    id="dropdownMenuLink2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className="cursor-pointer"
                  >
                    <MoreVertOutlinedIcon style={{ color: "white" }} />
                  </span>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="dropdownMenuLink2"
                    style={{
                      minWidth: "200px",
                      borderRadius: "0.5rem",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <li>
                      <span
                        className="badge badge-soft-dark fs-13"
                        onClick={() => {
                          if (
                            newNotificationFlag === "" &&
                            unreadCount !== 0 &&
                            !loading
                          ) {
                            handleReadAllNotifications();
                          }
                        }}
                        style={{
                          cursor: unreadCount === 0 ? "not-allowed" : "pointer",
                          opacity: loading ? 0.5 : 1,
                          display: "block",
                          padding: "0.5rem 1rem",
                          borderRadius: "0.25rem",
                        }}
                      >
                        <DoneAllIcon
                          style={{
                            verticalAlign: "middle",
                            marginRight: "0.5rem",
                          }}
                        />
                        {loading ? "Loading..." : `Mark as Read All`}
                      </span>
                    </li>
                  </ul>
                </Box>
              </Row>
            </Box>
          </Box>
          <Box
            className="not-list"
            sx={{
              maxHeight: ITEM_HEIGHT * 6,
              overflow: "auto",
              scrollbarWidth: "thin",
              minHeight: "290px",
            }}
          >
            {notificationList?.length <= 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  height: "100%",
                  padding: "20px",
                }}
              >
                <Box sx={{ fontSize: "4rem", color: "gray", mb: 2 }}>
                  <i className="mdi mdi-bell-outline"></i>{" "}
                </Box>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  No Notifications Yet
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ textTransform: "none", mt: 1 }}
                >
                  you have no notifications right now
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ textTransform: "none", mt: 0.5 }}
                >
                  come back later
                </Typography>
              </Box>
            )}

            {notificationList?.map((user, index) => (
              <Box
                id="single-notification"
                className="text-reset notification-item d-block dropdown-item position-relative"
                key={index}
              >
                <Box className="d-flex">
                  <Box className="avatar-xs me-3">
                    <span className="avatar-title bg-soft-info text-info rounded-circle fs-16">
                      <i className="bx bx-badge-check"></i>
                    </span>
                  </Box>
                  <Box className="flex-1">
                    <span
                      onClick={() => {
                        handleNavigate(
                          user?.module_path,
                          // user?.assetid || user?.fk_int_record_code, //If the the notification is not releated to asset(assetid = null) then right side value will be used
                          user?.parentid,
                          user
                        );
                      }}
                      className={`stretched-link ${
                        user?.parentid ? "cursor-pointer" : ""
                      }`}
                    >
                      <h6 className="mt-0 mb-2 lh-base">
                        {user?.txt_notification}
                      </h6>
                    </span>
                    <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                      <span>
                        <i className="mdi mdi-clock-outline"></i>{" "}
                        {
                          formatDateTime(
                            user?.createdAt,
                            dateFormat,
                            timeZone,
                            timeFormat
                          ).timeZone.formattedDateFinal
                        }{" "}
                        {
                          formatDateTime(
                            user?.createdAt,
                            dateFormat,
                            timeZone,
                            timeFormat
                          ).timeZone.formattedTimed
                        }
                      </span>
                    </p>
                  </Box>
                </Box>
              </Box>
            ))}

            <Box className="my-3 text-center d-flex">
              {notificationList.length < notificationCount && (
                <>
                  <button
                    type="button"
                    onClick={handleLoadMore}
                    className="btn btn-primary mt-3 mx-auto d-block btn-sm"
                  >
                    Load More
                  </button>
                  <button
                    type="button"
                    onClick={handleViewAllNotification}
                    className="btn btn-primary mt-3 mx-auto d-block btn-sm"
                  >
                    View All Notifications
                  </button>
                </>
              )}
            </Box>
          </Box>
        </Menu>
      </Box>
    </React.Fragment>
  );
};

export default NotificationDropdown;
