import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { ToastMessage } from "../../Components/Common/ToastMessage";
import { useSelector } from "react-redux";
import { Card, Offcanvas, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Button, Col, Row, Form, Spinner } from "reactstrap";
import DataTable from "react-data-table-component";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Common/Loader";
import { WEBSITE_CATEGORIES } from "../../Components/Common/ModuleName";
import { socket } from "../../Components/Common/socket";
import { API_ROUTES } from "../../Api/ApiRoutes";
import _ from "lodash";
import { Box, TextField } from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import { CustomTableStyle } from "../../Components/Common/constants";
import { DeleteIcon } from "../../Components/Common/SvgIcons";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import common from "../../Components/Common/common";
import { getAuthToken, getUserId } from "../../Api/common";

const DeveloperSkills = () => {
  const token = getAuthToken();
  const userId = getUserId();
  const navigate = useNavigate();

  const [websiteData, setWebsiteData] = useState([]);
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState("");

  const permissionData = useSelector(
    (state) => state.userDetails.userPermission
  );
  const hasPermission = permissionData?.find(
    (item) => item.module_name === WEBSITE_CATEGORIES
  );

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      skill_name: "",
    },
    validationSchema: Yup.object({
      skill_name: Yup.string()
        .trim()
        .max(255)
        .required("Please enter the skills."),
    }),
    onSubmit: (values) => {
      updateDeveloperSkills(values);
    },
  });

  const getSkillData = useCallback(async (searchParams) => {
    try {
      setIsLoading(true);
      let params = searchParams || "";
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}skills/get-skills?searchFilter=${params}`
      );

      if (response.status === "success") {
        const responseData = common.decrypt(response.data);
        setWebsiteData(responseData);
      }
    } catch (error) {
      console.error("Error fetching skills data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const debouncedFetchData = _.debounce(() => {
    getSkillData(query);
  }, 500);

  useEffect(() => {
    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, getSkillData]);

  const handleClose = () => {
    setShow(false);
    setTimeout(() => {
      setId("");
      validation.resetForm();
    }, 500);
  };

  const handleShow = () => {
    setShow(true);
  };

  const updateData = (row) => {
    setId(row.id);
    validation.setValues({
      skill_name: row.skill_name,
    });
    setShow(true);
  };

  const updateDeveloperSkills = async (values) => {
    let data = JSON.stringify({
      user_id: userId,
      skill_name: values.skill_name,
    });

    let apiUrl = id
      ? API_ROUTES.UPDATE_DEVELOPER_SKILL + id
      : API_ROUTES.POST_DEVELOPER_SKILL;

    let config = {
      method: id ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: data,
    };

    try {
      setIsLoading(true);
      let result = await fetch(apiUrl, config);
      let response = await result.json();
      if (response.code === 403) {
        navigate("/auth-404-cover");
      }
      if (response.status === "success") {
        setShow(false);
        setId("");
        id
          ? socket.emit("updateWebsiteCategory")
          : socket.emit("addWebsiteCategory");
        validation.resetForm();
        const successMessage = id
          ? "skill updated successfully"
          : "skill added successfully";
        ToastMessage.Success(successMessage);
        getSkillData();
      }
      if (response.status === "fail") {
        setShow(false);
        ToastMessage.Error(response.message);
      }
    } catch (error) {
      console.error("Error updating skill:", error);
      setShow(false);
      setIsLoading(false);
      ToastMessage.Error("An error occurred while updating skill.");
    }
  };

  const deleteWebsiteCategory = async (id) => {
    let apiUrl = `${process.env.REACT_APP_BASE_URL}skills/delete-skill/${id}`;
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: apiUrl,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response.code === 403) {
          navigate("/auth-404-cover");
        }
        if (response.status === "success") {
          navigate("/settings");
          getSkillData();
          socket.emit("deleteWebsiteCategory");
        }
        if (response.status === "fail") {
          getSkillData();
          navigate("/settings");
        }
      })
      .catch((error) => {
        return null;
      });
  };

  const handleDeleteAlert = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-dark mx-2",
        cancelButton: "btn btn-primary mx-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteWebsiteCategory(id);
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "skills has been deleted.",
            "success"
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "skills is safe :)",
            "error"
          );
        }
      });
  };

  const columns = [
    {
      name: "Skills",
      selector: (row) => row.skill_name,
      sortable: true,
    },
    {
      name: (
        <span className="text-center">
          {" "}
          {hasPermission &&
          (hasPermission?.canModify != null || hasPermission?.canDelete != null)
            ? "Actions"
            : ""}
        </span>
      ),
      cell: (row) => (
        <span className="table-action">
          {hasPermission && hasPermission?.canModify != null ? (
            <>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-edit-${row.id}`}>Edit</Tooltip>}
              >
                <span id="create-btn" onClick={() => updateData(row)}>
                  <EditOutlinedIcon />
                </span>
              </OverlayTrigger>
            </>
          ) : (
            ""
          )}
          {hasPermission && hasPermission?.canDelete != null ? (
            <>
              {/* <span onClick={() => handleDeleteAlert(row.id)}>
                <DeleteIcon />
              </span> */}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-delete-${row.id}`}>Delete</Tooltip>
                }
              >
                <span onClick={() => handleDeleteAlert(row.id)}>
                  <DeleteIcon />
                </span>
              </OverlayTrigger>
            </>
          ) : (
            ""
          )}
        </span>
      ),
      grow: 0.5,
      center: true,
    },
  ];

  const handleReset = () => {
    setQuery("");
    // setSearchQuery("");
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Card className="table-main-card">
        <Box className="table-card-header">
          <Box className="left-side">
            <Box className="search-box">
              <input
                type="text"
                value={query}
                className="form-control"
                placeholder="search for skills."
                onChange={(e) => {
                  setQuery(e.target.value);
                  // setSearchQuery(e.target.value);
                }}
              />
              <i className="ri-search-line search-icon"></i>
            </Box>
            <Button
              className="btn btn-primary reset-btn"
              onClick={() => {
                handleReset();
              }}
            >
              <LoopIcon />
            </Button>
          </Box>
          {hasPermission && hasPermission?.canCreate != null ? (
            <>
              <button
                type="button"
                className="btn btn-primary view-btn"
                id="create-btn"
                onClick={handleShow}
              >
                <i className="ri-user-add-line icon-md me-2 fs-15 align-middle"></i>
                Add skills
              </button>
            </>
          ) : null}
        </Box>
        <Card.Body>
          {isLoading ? (
            <Loader />
          ) : (
            <DataTable
              fixedHeight="500px"
              pointerOnHover
              pagination
              columns={columns}
              data={websiteData}
              customStyles={CustomTableStyle}
              className="table-design"
            />
          )}
        </Card.Body>
      </Card>

      <Offcanvas
        style={{ width: "31rem" }}
        show={show}
        onHide={handleClose}
        placement="end"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>
            {id ? <>Edit Skills</> : <>Add Skills</>}{" "}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={validation.handleSubmit}>
            <Row>
              <Col lg={12} className="mb-3">
                {/* <Label>Skills *</Label> */}
                <TextField
                  fullWidth
                  label="skills"
                  name="skill_name"
                  id="outlined-size-small"
                  placeholder="Enter your skills"
                  value={validation.values.skill_name}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  // invalid={
                  //   validation.errors.skill_name &&
                  //   validation.touched.skill_name
                  //     ? true
                  //     : false
                  // }
                />
                {validation.errors.skill_name &&
                  validation.touched.skill_name && (
                    <span className="error-text">
                      {validation.errors.skill_name}
                    </span>
                  )}
              </Col>
              <Col lg={12} style={{ alignItems: "center" }}>
                <Button
                  className="btn btn-dark"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? <Spinner size="sm" variant="light" /> : "Submit"}
                </Button>
                <Button className="btn btn-primary ms-2" onClick={handleClose}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
};

export default DeveloperSkills;
