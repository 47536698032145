import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Modal } from "react-bootstrap";
import { Col, Form, ModalBody, Row } from "reactstrap";
import axios from "axios";
import Flatpickr from "react-flatpickr";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
import TicketingSystem from "../../TicketingSystem/TicketingSystem";
import Activity from "./Tabs/Activity";

import CommentsAndNotes from "./Tabs/CommentsAndNotes";
import AnalyticsTable from "./common/AnalyticsTable";

import WebsiteAudienceMetrics from "./common/WebsiteAudienceMetrics";
import Performance from "./common/PerformanceCard";
import UpTime from "./common/UpTime";
import { API_ROUTES } from "../../../Api/ApiRoutes";
import SecurityCard from "./common/SecurityCard";

import {
  fetchOnlineAssetsReq,
  fetchSecurityScanTimeReq,
  postScheduleDataReq,
  refreshTestApiReq,
} from "../../../Api/NewDashboard/DashboardAnalyticTab/DvdlTabsReq";
import {
  fetchOnlineAssetsRes,
  fetchSecurityScanTimeRes,
  postScheduleDataRes,
  refreshTestApiRes,
} from "../../../Api/NewDashboard/DashboardAnalyticTab/DvdlTabsRes";
// import { useSelector } from "react-redux";
import PerformanceDetail from "./Tabs/PerformanceDetail";

import Choices from "choices.js";
import "choices.js/public/assets/styles/choices.min.css";
import { ToastContainer } from "react-toastify";
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { TabStyle } from "../constant";
import { WebAnalytics } from "./Tabs/WebAnalytics";
import WebsiteSecurity from "./WebsiteSecurity/WebsiteSecurity";
import UptimeReportDetails from "../../Reporting/UptimeReport/UptimeReportDetail";
import common from "../../../Components/Common/common";
import { getAuthToken, getUserId } from "../../../Api/common";

const DvdlTabs = ({
  assetID = null,
  ticketShow = false,
  activeTab = "",
  showActivity = false,
  setActiveTab,
  headerUpdate,
  sendData,
}) => {
  const token = getAuthToken();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const choicesRef = React.useRef(null);

  const userId = getUserId();
  const [customActiveTab, setcustomActiveTab] = useState(activeTab || "1");

  useEffect(() => {
    setActiveTab(customActiveTab);
  }, [customActiveTab, setActiveTab]);

  // const { apiDataLoading, apiLoadingAsset } = useSelector(
  //   (state) => state.onlineAssets
  // );

  const [typedata, setType] = useState("1");
  const [immuniWeb, setImmuniWeb] = useState([]);
  const [iwTest, setTest] = useState([]);
  const [userName, setUserName] = useState("");
  const [notesPermission, setNotesPermission] = useState("");
  const [scheduleDate, setScheduleDate] = useState("");
  const [securityHistoryScanTime, setSecurityHistoryScanTime] = useState([]);
  const [runScan, setRunscan] = useState(false);
  const [show, setShow] = useState(false);
  const [emailData, setEmailData] = useState([]);
  const [error, setError] = useState("");
  const [uptimeData, setUptimeData] = useState(null);
  const [securityData, setSecurityData] = useState(null);
  const [performanceData, setPerformanceData] = useState(null);

  const handleCloseInput = () => {
    setError("");
    setShow(false);
  };

  const handleSaveChangesClick = useCallback(() => {
    const selectedItems = choicesRef.current.getValue(true);
    if (selectedItems.length === 0) {
      setError("Please enter email address.");
    } else {
      setEmailData(selectedItems);
      handleCloseInput();
      ToastMessage.Success("report sent successfully.");
    }
  }, []);

  useEffect(() => {
    if (show) {
      const element = document.getElementById("choices-email-input");
      if (element) {
        const choicesInstance = new Choices(element, {
          removeItemButton: true,
          duplicateItemsAllowed: true,
          maxItemCount: 10,
          placeholderValue: "Enter email address",
          addItemFilter: (value) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (emailRegex.test(value)) {
              setError("");
              return true;
            } else {
              return false;
            }
          },
        });
        choicesRef.current = choicesInstance;
      }
    }
  }, [show]);

  let daysdata = "30";
  let analyticDaysData = "1";

  const [showFirstModal, setShowFirstModal] = useState(false);
  const [scheduleErrorMessage, setScheduleErrorMessage] = useState(null);
  const [scanType, setScanType] = useState("false");

  const handleFirstModalClose = () => {
    setShowFirstModal(false);
  };

  const handleScanTypeChange = (event) => {
    setScanType(event.target.value);
  };

  useEffect(() => {
    if (activeTab) {
      setcustomActiveTab(activeTab);
    }
  }, [activeTab]);

  const MemoizedMyHeaders = useMemo(() => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);
    return headers;
  }, [token]);

  const MemoizedUrlencoded = useMemo(() => {
    const URLSearch = {};

    if (emailData.length > 0) {
      URLSearch.sendResponse = true;
      URLSearch.email = emailData;
    }
    if (daysdata !== undefined) {
      URLSearch.uptimeDays = daysdata.toString();
    }
    if (analyticDaysData !== undefined) {
      URLSearch.days = analyticDaysData.toString();
    }
    if (typedata !== undefined) {
      URLSearch.system_type = typedata.toString();
    }
    URLSearch.assets_id = params.id;

    return URLSearch;
  }, [emailData, daysdata, analyticDaysData, typedata, params.id]);

  const memoizedRequestOptions = useMemo(() => {
    return {
      method: "POST",
      headers: MemoizedMyHeaders,
      body: JSON.stringify(MemoizedUrlencoded),
      redirect: "follow",
    };
  }, [MemoizedMyHeaders, MemoizedUrlencoded]);

  const [assetsData, setAssetsData] = useState(null);

  const assetsDataRef = useRef(assetsData);

  useEffect(() => {
    assetsDataRef.current = assetsData;
  }, [assetsData]);

  const fetchOnlineAssets = useCallback(async () => {
    try {
      const { url, userData } = fetchOnlineAssetsReq({ params, token });

      let result = await fetch(url, userData);
      let response = await result.json();

      fetchOnlineAssetsRes({
        response,
        setAssetsData,
        navigate,
        setNotesPermission,
        sendData,
      });
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, navigate, token]);

  useEffect(() => {
    fetchOnlineAssets();
  }, [fetchOnlineAssets, headerUpdate]);
  const intervalId = useRef(null);

  //Polling did from useEffect
  useEffect(() => {
    const startPolling = () => {
      intervalId.current = setInterval(() => {
        if (assetsDataRef.current === null) fetchOnlineAssets();  
        else clearInterval(intervalId);
      }, 30000); // Poll every 30 seconds
    };

    if (assetsData?.isAPIFetched === false && !intervalId.current) {
      startPolling();
    }

    if (assetsData?.isAPIFetched === true && intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }

    fetchOnlineAssets();

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [fetchOnlineAssets, assetsData?.isAPIFetched, headerUpdate]);

  const refreshTestApi = async (assetID, userID) => {
    setRunscan(true);
    try {
      const { url, userData } = refreshTestApiReq({ assetID, userID, token });

      const result = await fetch(url, userData);
      let response = await result.json();

      refreshTestApiRes({
        response,
        setTest,
        ToastMessage,
      });
      setRunscan(false);
    } catch (error) {
      console.error(error);
      setRunscan(false);
    }
  };

  const fetchImmuniWeb = useCallback(async () => {
    try {
      let immuni = await fetch(
        API_ROUTES.GET_IMMUNI_WEB_DATA,
        memoizedRequestOptions
      );
      let data1 = await immuni.json();
      const encryptedData = common.decrypt(data1.data);
      data1.data = encryptedData;
      const encryptedResult = common.decrypt(data1.message);
      data1.message = encryptedResult;

      if (data1.status === "success") {
        setUserName(data1.userName);
        setImmuniWeb(data1.data);
        // setWebPermission(data1.message.web_security);
        // setNotesPermission(data1.message.notes);
      }
      if (data1.status === "fail") {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  }, [memoizedRequestOptions]);

  const handleDate = (date) => {
    setScheduleDate(date[0]);
    if (date[0]) {
      setScheduleErrorMessage(null);
    }
  };

  const postScheduleData = async (recurringScan) => {
    try {
      const { url, userData } = postScheduleDataReq({
        token,
        userName,
        userId,
        params,
        scheduleDate,
        recurringScan,
      });

      let result = await fetch(url, userData);
      let response = await result.json();

      postScheduleDataRes({
        response,
        ToastMessage,
        // fetchSecurityHistory,
        fetchSecurityScanTime,
        setScheduleDate,
        setShowFirstModal,
        setScanType,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSecurityScanTime = useCallback(async () => {
    try {
      const { url, userData } = fetchSecurityScanTimeReq({
        params,
        userId,
        token,
      });

      let scheduleSecurityTime = await fetch(url, userData);
      let response = await scheduleSecurityTime.json();
      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;
      fetchSecurityScanTimeRes({
        response,
        setSecurityHistoryScanTime,
      });
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, userId, token]);

  const currentDateTime = new Date();

  let nearestDate = null;

  securityHistoryScanTime.forEach((dateTimeString) => {
    const date = new Date(dateTimeString.schedule_date_time);

    if (date > currentDateTime && (!nearestDate || date < nearestDate)) {
      nearestDate = date;
    }
  });

  useEffect(() => {
    fetchImmuniWeb();
    fetchSecurityScanTime();
  }, [
    fetchImmuniWeb,
    fetchSecurityScanTime,
    typedata,
    daysdata,
    analyticDaysData,
    iwTest,
  ]);

  const handleActivityFunction = () => {};
  const currentRunningDate = new Date();

  const isCurrentDate = (selectedDate) => {
    const selectedDateTime = new Date(selectedDate);

    return (
      selectedDateTime.getDate() === currentRunningDate.getDate() &&
      selectedDateTime.getMonth() === currentRunningDate.getMonth() &&
      selectedDateTime.getFullYear() === currentRunningDate.getFullYear()
    );
  };

  const getMinTime = (selectedDate) => {
    return isCurrentDate(selectedDate)
      ? currentRunningDate.toLocaleTimeString([], {
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
        })
      : "00:00";
  };

  const intialMinTime = currentRunningDate.toLocaleTimeString([], {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });

  const [assetsvalue, setAssetsvalue] = useState("1");
  const handleChangeAssets = (event, newValue) => {
    setAssetsvalue(newValue);
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (location?.state?.tab) setAssetsvalue(location?.state?.tab);
    if (location?.state?.tabValueFor === "attachmentTab") {
      setAssetsvalue("2");
      delete location?.state;
    }
    if (location?.state?.tabValueFor === "securityTab") {
      setAssetsvalue("4");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // fetch analytics data
  const [googleData, setGoogleData] = useState(null);
  const [fetchingData, setFetchingData] = useState(true);

  const googleDataRef = useRef(googleData);

  useEffect(() => {
    if (googleData !== null) {
      if (Object.keys(googleData)?.length <= 6) return;
      googleDataRef.current = googleData;
    }
  }, [googleData]);

  const fetchAnalyticsData = useCallback(
    async (days) => {
      try {
        setFetchingData(true);

        let response = await axios.post("asset/analytics-data", {
          assets_id: params.id,
          day: days || "30",
        });

        const decryptedData = common.decrypt(response.data);
        response.data = decryptedData;

        if (response.status === "success") {
          setGoogleData(response.data);
        }
      } catch (error) {
        console.error("Error fetching analytics data:", error);
      } finally {
        setFetchingData(false); // Stop showing skeleton
      }
    },
    [params.id]
  );

  useEffect(() => {
    fetchAnalyticsData();
  }, [fetchAnalyticsData]);

  //Polling is done using the useEffect
  useEffect(() => {
    let intervalId;

    const startPolling = () => {
      intervalId = setInterval(() => {
        if (googleDataRef.current === null) fetchAnalyticsData();
        else clearInterval(intervalId);
      }, 30000);
    };

    if (assetsData?.isAPIFetched === false) {
      startPolling();
    } else if (assetsData?.isAPIFetched === true && intervalId) {
      clearInterval(intervalId);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [assetsData?.isAPIFetched, fetchAnalyticsData]);

  const handleUptimeData = (data) => {
    setUptimeData(data);
  };

  const handleSecurityData = (data) => {
    setSecurityData(data);
  };

  const handlePerformanceData = (data) => {
    setPerformanceData(data);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Box className="assets-tabs">
        <TabContext value={assetsvalue}>
          <Box>
            <TabList
              onChange={handleChangeAssets}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              sx={TabStyle}
            >
              <Tab label="Statistics" value="1" />
              <Tab label="Activity" value="2" />
              <Tab label="Web. Analytics" value="3" />
              <Tab label="Web. Security" value="4" />
              <Tab label="Performance Matrix" value="5" />
              <Tab label="Up Time" value="6" />
              <Tab label="Tickets" value="7" />
              <Tab label="Comments" value="8" />
            </TabList>
          </Box>

          <TabPanel value="1" className="tabpanel">
            <Row className="g-3 statistics-tabpanel">
              <Col xxl={4} xl={6} lg={6} md={6} sm={12}>
                <UpTime
                  requestOptions={memoizedRequestOptions}
                  assetId={params.id}
                  assetsData={assetsData}
                  onDataReceived={handleUptimeData}
                />
              </Col>
              <Col xxl={4} xl={6} lg={6} md={6} sm={12}>
                <Performance
                  requestOptions={memoizedRequestOptions}
                  typedata={typedata}
                  iwTest={iwTest}
                  setType={setType}
                  assetId={params.id}
                  assetsData={assetsData}
                  onDataReceived={handlePerformanceData}
                />
              </Col>
              <Col xxl={4} xl={6} lg={6} md={6} sm={12}>
                <SecurityCard
                  paramsId={params.id}
                  userId={userId}
                  refreshTestApi={refreshTestApi}
                  runScan={runScan}
                  immuniWeb={immuniWeb}
                  assetsData={assetsData}
                  onDataReceived={handleSecurityData}
                />
              </Col>
              <Col xxl={4} xl={6} lg={6} md={6} sm={12} className="order-xxl-5">
                <AnalyticsTable
                  paramsId={params.id}
                  assetsData={assetsData}
                  fetchingData={fetchingData}
                  googleData={googleData}
                  fetchAnalyticsData={fetchAnalyticsData}
                />
              </Col>
              <Col
                xxl={8}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                className="order-xxl-4"
              >
                <WebsiteAudienceMetrics
                  assetsData={assetsData}
                  paramsId={params.id}
                />
              </Col>
            </Row>
          </TabPanel>
          <TabPanel value="2" className="tabpanel">
            <Activity
              id={params.id}
              iwTest={iwTest}
              showActivity={showActivity}
              handleActivityFunction={handleActivityFunction}
            />
          </TabPanel>
          <TabPanel value="3" className="tabpanel">
            {assetsData?.isAPIFetched === false &&
            googleDataRef.current === null ? (
              <div className="card mt-5">
                <div className="card-body d-flex flex-column flex-grow-1 justify-content-center align-items-center">
                  <div className="text-center">
                    <h5 className="text-muted">
                      We are currently retrieving the analytics data. Please
                      hold on for a moment while we gather all the details.
                    </h5>
                  </div>
                </div>
              </div>
            ) : (
              <WebAnalytics id={params.id} assetsData={assetsData} />
            )}
          </TabPanel>
          <TabPanel value="4" className="tabpanel">
            <WebsiteSecurity
              assetsData={assetsData}
              securityData={securityData}
              refreshTestApi={refreshTestApi}
              paramsId={params.id}
              userId={userId}
            />
          </TabPanel>
          <TabPanel value="5" className="tabpanel">
            {typeof performanceData?.test_id === "number" ||
            assetsData?.isAPIFetched ? (
              <PerformanceDetail id={params.id} />
            ) : (
              <div className="card mt-5">
                <div className="card-body d-flex flex-column flex-grow-1 justify-content-center align-items-center">
                  <div className="text-center">
                    <h5 className="text-muted">
                      We are currently assessing the website's performance. The
                      data will be displayed shortly.
                    </h5>
                  </div>
                </div>
              </div>
            )}
          </TabPanel>
          <TabPanel value="6" className="tabpanel">
            {typeof uptimeData?.id === "number" || assetsData?.isAPIFetched ? (
              <UptimeReportDetails isOnlineAsset={true} />
            ) : (
              <div className="card mt-5">
                <div className="card-body d-flex flex-column flex-grow-1 justify-content-center align-items-center">
                  <div className="text-center">
                    <h5 className="text-muted">
                      We are currently generating the website status report.
                      Please hold on for a moment while we gather the necessary
                      data.
                    </h5>
                  </div>
                </div>
              </div>
            )}
          </TabPanel>
          <TabPanel value="7" className="tabpanel">
            <TicketingSystem
              assetID={assetID}
              ticketShow={ticketShow}
              assetsData={assetsData}
            />
          </TabPanel>
          <TabPanel value="8" className="tabpanel">
            <CommentsAndNotes
              id={params.id}
              userId={userId}
              notesPermission={notesPermission}
              onNotesAdded={handleActivityFunction}
            />
          </TabPanel>
        </TabContext>
      </Box>

      <Modal
        show={showFirstModal}
        onHide={handleFirstModalClose}
        aria-labelledby="firstmodal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Schedule Security Scan</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mt-4">
          <h6>Schedule Security Scan</h6>
          <Flatpickr
            placeholder="Select date"
            className="form-control bg-light border-light border-0"
            value={scheduleDate}
            options={{
              enableTime: true,
              dateFormat: "Y-m-d H:i",
              minDate: "today",
              minTime: scheduleDate ? getMinTime(scheduleDate) : intialMinTime,
            }}
            onChange={handleDate}
          />
          {scheduleErrorMessage !== null && (
            <p style={{ color: "red" }}>{scheduleErrorMessage}</p>
          )}
          <div className="mt-4">
            <label>
              <input
                name="scan-type"
                type="radio"
                value="false"
                checked={scanType === "false"}
                onChange={handleScanTypeChange}
              />
              <span style={{ paddingLeft: "5px" }}>Single Scan</span>
            </label>

            <label style={{ paddingLeft: "50px" }}>
              <input
                name="scan-type"
                type="radio"
                value="true"
                checked={scanType === "true"}
                onChange={handleScanTypeChange}
              />
              <span style={{ paddingLeft: "5px" }}>Recurring Scan</span>
            </label>
          </div>
          <div className="mt-5 pt-3">
            <div className="hstack gap-2 justify-content-center">
              <Button
                variant="soft-danger"
                onClick={() => {
                  if (!scheduleDate) {
                    setScheduleErrorMessage("please select the date first");
                  } else {
                    postScheduleData(scanType);
                  }
                }}
              >
                submit
              </Button>
              <Button
                variant="light"
                onClick={() => {
                  handleFirstModalClose();
                  setScanType("false");
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show}
        onHide={handleCloseInput}
        style={{ display: "block", paddingLeft: "0px" }}
        centered
      >
        <ModalBody className="py-3 px-5">
          <Form>
            <div className="mb-3">
              <label htmlFor="choices-email-input">Email</label>
              <input
                id="choices-email-input"
                className="form-control"
                type="text"
                placeholder="Enter email address"
              />
            </div>
            {error && <div className="text-danger">{error}</div>}
          </Form>
        </ModalBody>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseInput}>
            Close
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSaveChangesClick}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default DvdlTabs;
