import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Form, Row, Spinner } from "reactstrap";
// import Select from "react-select";
import { ToastMessage } from "../../../../Components/Common/ToastMessage";
import { ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

import { changePasswordReq } from "../../../../Api/MyProfile/PagesProfileSetting/PagesProfileSettingReq";
import { ChangePasswordRes } from "../../../../Api/MyProfile/PagesProfileSetting/PagesProfileSettingRes";
// import { USERS } from "../../../../Components/Common/ModuleName";
import { useDispatch } from "react-redux";
import { addUserData } from "../../../../store/userData/action";
// import axios from "axios";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
// import { MenuHeight } from "../../../NewDashboard/constant";
import { Button } from "react-bootstrap";
import common from "../../../../Components/Common/common";
import MobileNumberComponent from "../../../userManagement/DepartmentUser/MobileNumberComponent";
import { getAuthToken } from "../../../../Api/common";

const Settings = ({ setUpdatedValue, updatedValue, setProfilevalue }) => {
  const token = getAuthToken();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const permissionData = useSelector(
  //   (state) => state.userDetails.userPermission
  // );
  // const hasPermission = permissionData?.find(
  //   (item) => item.module_name === USERS
  // );

  // const userData = useSelector((state) => state.userDetails.userData);

  const [data, setData] = useState([]);

  // const [activeTab, setActiveTab] = useState("1");
  // // const [roleOptions, setRoleOptions] = useState([]);
  // const [selectedImage, setSelectedImage] = useState(null);
  // const [selectedCoverImage, setSelectedCoverImage] = useState(null);

  // const [showOldPassword, setShowOldPassword] = useState(false);
  // const [showNewPassword, setShowNewPassword] = useState(false);
  // const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  // const [websiteCategory, setWebsiteCategory] = useState([]);
  const [postIsLoading, setPostIsLoading] = useState(false);
  const [countryViceNumberLength, setCountryViceNumberLength] = useState(0);
  const [selectedCountryOption, setSelectedCountryOption] = useState({
    name: "",
    phone: "", 
  });

  // const [departmentSelectOpt, setDepartmentSelectOpt] = useState([null]);
  // const [errors, setErrors] = useState(null);
  // const [success, setSuccess] = useState(null);
  // const [statusSelectOpt, setStatusSelectOpt] = useState({
  //   value: "Active",
  //   label: "Active",
  // });

  // const tabChange = (tab) => {
  //   if (activeTab !== tab) setActiveTab(tab);
  // };

  useEffect(() => {
    getUserData();
    // getUserType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserData = async () => {
    let reqData = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    let result = await fetch(
      `${process.env.REACT_APP_BASE_URL}users-website/user-management-users/${params.id}`,
      reqData
    );
    let response = await result.json();
    if (response.status === "success") {
      const responseData = common.decrypt(response.data);
      setSelectedCountryOption({
        name: responseData.country !== null ? responseData.country : "",
        phone: responseData.phone || "", 
      });
      setCountryViceNumberLength(responseData.phoneLength || 0);
      setData(responseData);
    }
    if (response.status === "fail") {
      ToastMessage.Error("Error in fetching the data, Please try again later");
    }
  };

  const changePasswordValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },

    validationSchema: Yup.object({
      oldPassword: Yup.string().max(255).required("Old password is required."),
      newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("This field is required")
        .test(
          "notSameAsOldPassword",
          "New password must be different from the old password",
          function (value) {
            return value !== this.parent.oldPassword;
          }
        ),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm Password Required"),
    }),

    onSubmit: (values) => {
      changePassword(values);
    },
  });

  const changePassword = async (values) => {
    try {
      const { url, reqData } = changePasswordReq(values);

      let result = await fetch(url, reqData);
      let response = await result.json();

      ChangePasswordRes(
        changePasswordValidation,
        response,
        // setErrors,
        // setSuccess,
        navigate,
        params.id
      );
    } catch (error) {
      return null;
    }
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      fullName: data ? data.user_name : "",
      phone: data ? data.contact_no : "",
      email: data ? data.email : "",
      // department: data.department_id,
      organization: data.organization || "",
      // role: data.role,
      organizationAddress: data.organization_address || "",
      status: data ? data.status : "Active",
      description: data.description || "",
      user_image: data ? data.user_image : "",
      profile_picture: data ? data.profile_picture : "",
    },

    validationSchema: Yup.object({
      fullName: Yup.string().max(255).required("Please enter full name."),
      // phone: Yup.string().required("contact no. is required"),
      // phone: Yup.string().matches(
      //   /^(\(\d{3}\)[- .]?)?\d{3}[- .]?\d{4}|\d{10}$/,
      //   "Please enter valid phone number."
      // ),

      phone: Yup.string()
        .nullable()
        .test("length", null, function (value) {
          const { path, createError } = this;
          if (value && value.length !== countryViceNumberLength) {
            return createError({
              path,
              message: `Phone number must be exactly ${countryViceNumberLength} digits`,
            });
          }
          return true;
        })
        .matches(
          new RegExp(`^\\d{${countryViceNumberLength}}$`),
          `Please enter a valid phone number with exactly ${countryViceNumberLength} digits`
        ),

      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Please enter email id."),
      // department: Yup.string().max(255).required("Please enter department."),
      organization: Yup.string().max(255).nullable(),
      // role: Yup.string().max(255).required("role is required"),
      organizationAddress: Yup.string().max(255).nullable(),
      status: Yup.string().max(255).required("Please enter status."),
      description: Yup.string().max(255).nullable(),
      //   user_image: Yup.mixed().required("Please select the Image."),
      //   profile_picture: Yup.mixed().required("Please select the Cover Image."),
    }),

    onSubmit: (values) => {
      updateUsers(values);
    },
  });

  const updateUsers = async (values) => {
    try {
      setPostIsLoading(true);
      let data = new FormData();
      if (values.user_image && values.user_image.constructor === File) {
        data.append("user_image", values.user_image);
      }

      if (
        values.profile_picture &&
        values.profile_picture.constructor === File
      ) {
        data.append("profile_picture", values.profile_picture);
      }
      data.append("user_name", values.fullName);
      data.append("organization", values.organization);
      data.append("contact_no", values.phone);
      data.append("email", values.email);
      data.append("organization_address", values.organizationAddress);
      // data.append("department_id", values.department);
      // data.append("role", values.role);
      data.append("status", values.status);
      data.append("description", values.description);
      data.append("country", selectedCountryOption?.name || "");
      // data.append("country", )

      let reqData = {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: data,
      };

      let result = await fetch(
        `${process.env.REACT_APP_BASE_URL}users-website/update-user-management-users/${params.id}`,
        reqData
      );
      let response = await result.json();
      if (response.status === "success") {
        ToastMessage.Success("Details has been updated successfully.");
        dispatch(addUserData(response.data));
        localStorage.setItem("user_name", values.fullName);
        setTimeout(() => {
          navigate("/pages-profile/" + params.id);
        }, 2000);
        getUserData();
        setProfilevalue("1");
        setUpdatedValue(!updatedValue);
        setPostIsLoading(false);
      } else {
        getUserData();
        setPostIsLoading(false);
        ToastMessage.Error("An error occurred. Please try again later.");
      }
    } catch (error) {
      setPostIsLoading(false);
      return null;
    } finally {
      setPostIsLoading(false);
    }
  };


  const options = [
    { value: "Active", label: "Active" },
    { value: "In-Active", label: "In-Active" },
  ];


  const handleStatusSelectChange = (event) => {
    const option = options.find((opt) => opt.value === event.target.value);
    validation.setFieldValue("status", option.value);
    // setStatusSelectOpt(option);
  };

  return (
    <React.Fragment>
      <ToastContainer closeButton={true} />
      <Form onSubmit={validation.handleSubmit}>
        <Row className="g-4">
          <Col md={6} xl={4}>
            {/* <Label>Full Name *</Label> */}
            <TextField
              fullWidth
              label="Full name *"
              name="fullName"
              type="text"
              placeholder="Enter your full Name"
              value={validation.values.fullName || ""}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
              // invalid={
              //   validation.errors.fullName && validation.touched.fullName
              //     ? true
              //     : false
              // }
            />
            {validation.errors.fullName && validation.touched.fullName ? (
              <span className="error-text">{validation.errors.fullName} </span>
            ) : null}
          </Col>

          <Col md={6} xl={4}>
            <MobileNumberComponent
              setCountryViceNumberLength={setCountryViceNumberLength}
              validation={validation}
              selectedCountryOption={selectedCountryOption}
              setSelectedCountryOption={setSelectedCountryOption}
            />
          </Col>

          <Col md={6} xl={4}>
            <TextField
              fullWidth
              label="Email *"
              name="email"
              value={validation.values.email || ""}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
              type="email"
              placeholder="Enter your email"
              
            />
            {validation.errors.email && validation.touched.email ? (
              <span className="error-text">{validation.errors.email} </span>
            ) : null}
          </Col>
          <Col md={6} xl={4}>
            <TextField
              fullWidth
              label="Organization"
              type="text"
              placeholder="Enter your organization"
              name="organization"
              value={validation.values.organization || ""}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {validation.errors.organization &&
            validation.touched.organization ? (
              <span className="error-text">
                {validation.errors.organization}{" "}
              </span>
            ) : null}
          </Col>
          <Col md={6} xl={4}>
            <TextField
              fullWidth
              label="Organization Address"
              name="organizationAddress"
              value={validation.values.organizationAddress || ""}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
            
              type="text"
              id="designationInput"
              placeholder="Enter your organization Address"
            />
            {validation.errors.organizationAddress &&
            validation.touched.organizationAddress ? (
              <span style={{ color: "red" }}>
                {validation.errors.organizationAddress}{" "}
              </span>
            ) : null}
          </Col>
          <Col md={6} xl={4}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                name="status"
                value={validation.values.status || "Active"}
                onChange={handleStatusSelectChange}
                onBlur={validation.handleBlur}
                renderValue={(selected) =>
                  options.find((opt) => opt.value === selected)?.label ||
                  "Select an option"
                }
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select an option
                </MenuItem>
                {options.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.value !== "Active"}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {validation.errors.status && validation.touched.status && (
                <span>{validation.errors.status}</span>
              )}
            </FormControl>
            {validation.errors.status && validation.touched.status ? (
              <span style={{ color: "red" }}>{validation.errors.status} </span>
            ) : null}
          </Col>
          <Col lg={12}>
            <TextField
              id="exampleFormControlTextarea"
              label="Description"
              multiline
              rows={3}
              value={validation.values.description || ""}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              name="description"
              fullWidth
              variant="outlined"
              placeholder="Enter your description"
              InputLabelProps={{
                shrink: true,
              }}
            />

            {validation.errors.description && validation.touched.description ? (
              <span style={{ color: "red" }}>
                {validation.errors.description}{" "}
              </span>
            ) : null}
          </Col>
          {/* {hasPermission?.canModify !== null ? ( */}
          <Col lg={12}>
            <div className="hstack gap-2 justify-content-end">
              <Button
                type="submit"
                className="btn btn-dark"
                disabled={postIsLoading}
              >
                {postIsLoading ? (
                  <Spinner size="sm" variant="light" />
                ) : (
                  "Update"
                )}
              </Button>
              <Button onClick={() => setProfilevalue("1")}>Cancel</Button>
            </div>
          </Col>
          {/* ) : (
            ""
          )} */}
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default Settings;
