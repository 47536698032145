let BASE_URL = process.env.REACT_APP_BASE_URL;

export const API_ROUTES = {
  // Users
  GET_USERS: `${BASE_URL}users-website/user-management-users`,
  ADD_USERS: `${BASE_URL}users-website/post-user-management-users`,
  UPDATE_USERS: `${BASE_URL}users-website/update-user-management-users/`,
  GET_USERS_BY_ID: `${BASE_URL}users-website/user-management-users/`,
  GET_USERS_BY_ASSET_ID: `${BASE_URL}users-website/get-user-assets-id/`,
  CHANGE_PASSWORD: `${BASE_URL}user/change-password`,
  RESET_PASSWORD: `${BASE_URL}user/reset-password-email`,

  //Powerpanel User
  GET_POWERPANEL_DATA: `${BASE_URL}users-website/get-powerpanel-users`,
  ADD_POWERPANEL_USERS: `${BASE_URL}users-website/post-powerpanel-users`,
  UPDATE_POWERPANEL_USERS: `${BASE_URL}users-website/udpate-powerpanel-users/`,

  // Internal Users
  // GET_INTERNAL_USERS: `${BASE_URL}users-website/user-management-users`,
  GET_DEPARTMENT_USERS: `${BASE_URL}department-user/get-department-user`,
  ADD_DEPARTMENT_USERS: `${BASE_URL}department-user/post-department-user/`,
  UPDATE_DEPARTMENT_USERS: `${BASE_URL}department-user/post-department-user/`,
  UPDATE_DEPARTMENT_USERS_STATUS: `${BASE_URL}department-user/update-department-user-status/`,
  DELETE_DEPARTMENT_USERS: `${BASE_URL}department-user/delete-department-user/`,

  // Modules
  GET_MODULE: `${BASE_URL}modules/get-module`,

  // Roles
  GET_ROLES_PERMISSION: `${BASE_URL}role/get-roles-permisssion`,
  GET_ROLES_PERMISSION_BY_NAME: `${BASE_URL}role/get-roles-permisssionByName`,
  GET_ROLES: `${BASE_URL}role/get-roles`,
  GET_ROLE: `${BASE_URL}role/get-role`,

  // Website Guideline
  WEBSITE_GUIDELINE: `${BASE_URL}website-guideline/`,
  GET_WEBSITE_GUIDELINE: `${BASE_URL}website-guideline/get-website-guideline`,
  ADD_WEBSITE_GUIDELINE: `${BASE_URL}website-guideline/post-website-guideline`,
  UPDATE_WEBSITE_GUIDELINE: `${BASE_URL}website-guideline/update-website-guideline/`,
  DELETE_WEBSITE_GUIDELINE: `${BASE_URL}website-guideline/delete-website-guideline/`,

  // Branding Guideline
  GET_BRANDING_GUIDELINE: `${BASE_URL}branding-guideline/get-branding-guideline`,
  ADD_BRANDING_GUIDELINE: `${BASE_URL}branding-guideline/post-branding-guideline`,
  UPDATE_BRANDING_GUIDELINE: `${BASE_URL}branding-guideline/update-branding-guideline/`,
  DELETE_BRANDING_GUIDELINE: `${BASE_URL}branding-guideline/delete-branding-guideline/`,

  // Online Assets
  GET_ONLINE_ASSETS: `${BASE_URL}online-assets/online-assets-list`,
  GET_ONLINE_ASSETS_DEPARTMENT_WISE: `${BASE_URL}online-assets/online-assets-department-list`,
  GET_WEBSITE_CATEGORIES: `${BASE_URL}websiteCategories/get-category`,
  POST_WEBSITE_CATEGORIES: `${BASE_URL}websiteCategories/post-category`,
  UPDATE_WEBSITE_CATEGORIES: `${BASE_URL}websiteCategories/edit-category/`,

  // Online Assets Third Party Api
  GET_POWERPANEL_USERS: `${BASE_URL}online-assets/get-powerpanel-users`,
  GET_IMMUNI_WEB_DATA: `${BASE_URL}asset/web-security`,
  GET_IMMUNI_WEB_V2_DATA: `${BASE_URL}asset/web-security-data`,
  SEND_IMMUNI_WEB_REPORT: `${BASE_URL}asset/Send-web-security-report-data`,

  // GOOGLE DATA THIRD PARTY API
  GET_GOOGLE_IMMUNI_WEB_DATA: `${BASE_URL}site-data/web-security-data`,

  // Schedule Security
  GET_SCHEDULE_SECURITY: `${BASE_URL}website-management/get-schedule-security`,
  ADD_SCHEDULE_SECURITY: `${BASE_URL}website-management/post-schedule-security`,
  GET_SCHEDULE_SECURITY_SCANTIME: `${BASE_URL}website-management/get-schedule-security-scantime`,

  // Notifications
  GET_NOTIFICATIONS: `${BASE_URL}notifications/get-notification`,

  // Developer Skills
  GET_DEVELOPER_SKILL: `${BASE_URL}skills/get-skills`,
  POST_DEVELOPER_SKILL: `${BASE_URL}skills/post-skills`,
  UPDATE_DEVELOPER_SKILL: `${BASE_URL}skills/edit-skill/`,
};

// MY PACKAGES
export const GET_PURCHASE_STATUS_OF_ADMIN =
  "package/get-package-purchase-status";
