import React from "react";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Grid,
  ListItemIcon,
  Skeleton,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import InfoIcon from "@mui/icons-material/Info";
import common from "../../Components/Common/common";
import { getUserRole } from "../../Api/common";

export const ListSkeleton = ({ listsToRender }) => {
  return (
    <Grid container spacing={3} alignItems="center" padding={"10px"}>
      {Array(listsToRender)
        .fill(1)
        .map((_, index) => (
          <Grid item xs={4} key={index} style={{ height: "90px" }}>
            {" "}
            <div
              style={{
                display: "flex",
                height: "100%", // Parent height, skeleton will adjust to this
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: "40px",
              }}
            >
              <Skeleton
                variant="rounded"
                width="100%" // Adjusts to parent width
                height="100%" // Adjusts to parent height
              />
            </div>
          </Grid>
        ))}
    </Grid>
  );
};

const MyPackage = (props) => {
  const plan = props?.myPackage[0];
  const currentUserPackageStatus = props?.currentUserPackageStatus || null;
  const userRole = getUserRole();
  const serviceNameArray = ["assets", "users_limits"];

  return (
    <Box sx={{ borderRadius: "10px" }}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        style={{ marginBottom: "30px" }}
      >
        My Current Plan
      </Typography>

      <Card
        variant="outlined"
        sx={{
          borderRadius: "15px",
          padding: { xs: 3, sm: 5 },
          boxShadow: 0,
          border: 0,
          transition: "transform 0.3s",
        }}
      >
        <CardContent sx={{ flexGrow: 1, padding: 0 }}>
          {userRole !== "Super Admin" && (
            <p
              variant="p"
              align="right"
              sx={{
                mb: 3,
                fontSize: "1rem",
                color: "#333",
              }}
            >
              {currentUserPackageStatus?.["isPackageExpired"] ? (
                <span
                  style={{
                    display: "inline-block",
                    backgroundColor: "#1b2334",
                    padding: "5px 15px",
                    borderRadius: "50px",
                    color: "#ffffff",
                  }}
                >
                  <strong>Your plan expired</strong>{" "}
                  {currentUserPackageStatus?.["package_end_date"] &&
                    `on  : ${currentUserPackageStatus?.["package_end_date"]}`}
                </span>
              ) : (
                currentUserPackageStatus?.["package_end_date"] && (
                  <span
                    style={{
                      display: "inline-block",
                      backgroundColor: "#1b2334",
                      padding: "5px 15px",
                      borderRadius: "50px",
                      color: "#ffffff",
                    }}
                  >
                    <strong>Expired in:</strong>{" "}
                    {currentUserPackageStatus?.["package_end_date"] || ""}
                  </span>
                )
              )}
              {currentUserPackageStatus?.status === "In-Active" &&
                userRole !== "Admin" && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`user`}>{common.ADMINMESSAGE}</Tooltip>
                    }
                  >
                    <InfoIcon />
                  </OverlayTrigger>
                )}
            </p>
          )}

          <Typography
            className="text-capitalize fw-semibold"
            variant="h5"
            align="center"
            sx={{
              mb: 3,
              fontSize: { xs: "1.2rem", sm: "1.5rem" },
            }}
          >
            {plan?.packageName ? (
              plan.packageName
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Skeleton variant="rounded" width={170} height={35}></Skeleton>
              </div>
            )}
          </Typography>

          <Grid container spacing={3} alignItems="center">
            {plan?.packageServiceData ? (
              plan?.packageServiceData
                .filter(props.shouldDisplayService)
                .map((service) => {
                  const serviceValue = props.renderValue(service, plan, true);
                  return (
                    <Grid item xs={12} sm={6} md={4} key={service.serviceId}>
                      <Box
                        className="package-box h-100"
                        sx={{
                          display: "flex",
                          alignItems: "start",
                          padding: { xs: "15px", sm: "25px" },
                          backgroundColor: "#F5F7F8",
                          borderRadius: "10px",
                          boxShadow: "none",
                          transition: "0.3s",
                        }}
                      >
                        {/* Conditionally render icon based on value */}
                        <ListItemIcon
                          sx={{
                            minWidth: "auto",
                            marginRight: { xs: "8px", sm: "10px" },
                            marginTop: { xs: "2px", sm: "2px" },
                          }}
                        >
                          {service.value === "true" ? (
                            <CheckCircleIcon
                              sx={{
                                color: "primary.main",
                                fontSize: { xs: "1.5rem", sm: "2rem" },
                                fill: "#52c648",
                              }}
                            />
                          ) : service.value === "false" ? (
                            <CancelIcon
                              sx={{
                                color: "error.main",
                                fontSize: { xs: "1.5rem", sm: "2rem" },
                              }}
                            />
                          ) : (
                            <CheckCircleIcon
                              sx={{
                                color: "primary.main",
                                fontSize: { xs: "1.5rem", sm: "2rem" },
                                fill: "#52c648",
                              }}
                            />
                          )}
                        </ListItemIcon>

                        <Box>
                          <Typography
                            className="fw-semibold"
                            variant="body1"
                            sx={{ fontSize: "1rem" }}
                          >
                            {service.serviceName}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: "normal",
                              fontSize: "1rem",
                              color: serviceValue
                                ? "text.secondary"
                                : "error.main",
                            }}
                          >
                            {serviceNameArray?.includes(service.slug) &&
                            userRole !== "Super Admin"
                              ? currentUserPackageStatus?.[service.slug] +
                                " / " +
                                serviceValue
                              : serviceValue || "Not available"}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })
            ) : (
              <ListSkeleton listsToRender={9} />
            )}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default MyPackage;
