import CryptoJS from "crypto-js";
import { CRYPTO_TOKEN_SECRET_KEY } from "../config";

export const getUserRole = () => {
    let userRole = localStorage.getItem("role");
    let decryptedData;
    if (userRole) {
        decryptedData = CryptoJS.AES.decrypt(
            localStorage.getItem("role"),
            CRYPTO_TOKEN_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8);
    }
    return decryptedData || userRole;
};

export const getUserId = () => {
    let userId = localStorage.getItem("user_id");
    let decryptedData;
    if (userId) {
        decryptedData = CryptoJS.AES.decrypt(
            userId || "",
            CRYPTO_TOKEN_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8);
    }

    return (decryptedData && Number(decryptedData)) || userId;
};

export const getAuthToken = () => {
    let token = localStorage.getItem("token");
    let decryptedData;
    if (token) {
        decryptedData = CryptoJS.AES.decrypt(
            token,
            CRYPTO_TOKEN_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8);
    }

    return decryptedData || token;
};
