import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import './TextEditor.css'
import { getAuthToken } from "../../Api/common";

class TextEditor extends Component {
  render() {
    const { value, onChange, filename, apiUrl } = this.props;

    // const custom_config = {
    //   extraPlugins: [MyCustomUploadAdapterPlugin(filename, apiUrl)],
    //   toolbar: {
    //     items: ["bold", "italic", "link", "imageUpload"],
    //   },
    //   table: {
    //     contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    //   },
    // };

    const custom_config = {
      extraPlugins: [MyCustomUploadAdapterPlugin(filename, apiUrl)],
      toolbar: {
        items: ["bold", "italic", "link", "imageUpload"],
      },
      table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
      },
      image: {
        styles: [
          "alignLeft", "alignCenter", "alignRight", "full",
          "imageStyle:alignLeft", "imageStyle:alignCenter", "imageStyle:alignRight", "imageStyle:full",
        ],
        resizeOptions: [
          {
            name: 'imageResize:original',
            label: 'Original',
            value: null
          },
          {
            name: 'imageResize:50',
            label: '50%',
            value: '50'
          },
          {
            name: 'imageResize:75',
            label: '75%',
            value: '75'
          }
        ],
      },
    };

    return (
      <CKEditor
        required
        editor={ClassicEditor}
        config={custom_config}
        data={value}
        onChange={(event, editor) => {
          const data = editor.getData();
          onChange(data);
        }}
      />
    );
  };
};

function MyCustomUploadAdapterPlugin(filename, apiUrl) {
  return function (editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new MyUploadAdapter(loader, editor, filename, apiUrl);
    };
  };
};

class MyUploadAdapter {
  constructor(loader, editor, filename, apiUrl) {
    this.loader = loader;
    this.editor = editor;
    this.filename = filename;
    this.apiUrl = apiUrl;
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._readFile(file)
            .then((dataUrl) => {
              resolve({ default: dataUrl });
            })
            .catch((error) => {
              reject(error);
            });
        })
    );
  }

  abort() { }

  _readFile(file) {
    return new Promise(async (resolve, reject) => {
      const authToken = getAuthToken();
      const formData = new FormData();
      formData.append(this.filename, file);
      fetch(this.apiUrl,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: formData,
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Image upload failed");
          }
          return response.json();
        })
        .then((result) => {

          // Use the upload path directly from the API response
          const objectURL = result.data.filename;

          // Resolve with the upload path
          resolve(`${process.env.REACT_APP_BASE_URL}${objectURL}`);

          // Use the upload path directly from the API response
          //   const objectURL = result.data.filename;
          // Resolve with the upload path
          //   resolve(`${process.env.REACT_APP_BASE_URL}/${objectURL}`);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default TextEditor;
