import { API_ROUTES } from "../../ApiRoutes";
import { getAuthToken } from "../../common";

export const changePasswordReq = (props) => {
    const authToken = getAuthToken();

    const { oldPassword, newPassword } = props

    let url = API_ROUTES.CHANGE_PASSWORD

    let formData = {
        oldPassword: oldPassword,
        newPassword: newPassword
    }

    const reqData = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(formData),
    };

    return { url: url, reqData };
}
