import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import {
  Form,
  Offcanvas,
  Card,
  Modal,
  Button,
  CloseButton,
} from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import TicketingSystemTable from "./TicketingSystemTable";
import axios from "axios";
import { ToastMessage } from "../../Components/Common/ToastMessage";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormFeedback, Input, Spinner } from "reactstrap";
import { useSelector } from "react-redux";
import { TICKETING_SYSTEM } from "../../Components/Common/ModuleName";
import { socket } from "../../Components/Common/socket";
import _ from "lodash";
import Swal from "sweetalert2";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  AutoDropdownStyle,
  DropdownStyle,
  MenuHeight,
} from "../NewDashboard/constant";
import common, { convertMBToBytes } from "../../Components/Common/common";
import { API_ROUTES } from "../../Api/ApiRoutes";
import { useDefaultImageSize } from "../../Components/Hooks/UseDeafultImageSizeHook";
import { getAuthToken } from "../../Api/common";
import { VALIDATION_MESSAGE } from "../../Components/Common/Validation";

const TicketingSystem = ({
  paginationDisable = false,
  isDashboardView = false,
  assetID = null,
  ticketShow = false,
  setTileBoxTickets,
  showHeader,
  dragHandleProps,
  assetsData,
}) => {
  const navigate = useNavigate();
  const DEFAULT_IMAGE_SIZE = useDefaultImageSize();
  const LOGOTOOLTIP = `Only JPEG, JPG, PNG, and SVG images are allowed. The maximum size of the image is ${DEFAULT_IMAGE_SIZE} MB. Recommended image dimensions are 353px in width and 174px in height.`;

  const token = getAuthToken();
  const flatpickrRef = useRef(null);
  const location = useLocation();

  const [show, setShow] = useState(false);
  const [homeStatus, setHomeStatus] = useState("All");
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDate, setSelectedDate] = useState([]);
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isAddLoading, setAddLoading] = useState(null);
  const [isLoad, setIsLoad] = useState(null);
  const [isPriorityLoad, setIsPriorityLoad] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [websiteGroupOptions, setWebsiteGroupOptions] = useState([]);
  const [defaultSelectedOption, setDefaultSelectedOption] = useState("");

  const permissionData = useSelector(
    (state) => state.userDetails.userPermission
  );
  const hasPermission = permissionData?.find(
    (item) => item.module_name === TICKETING_SYSTEM
  );
  const userData = useSelector((state) => state.userDetails.userData);
  const userIsAdmin = userData?.isAdmin;
  const userDepartment = userData?.department_id;

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  useEffect(() => {
    if (location.state?.globalSearch) {
      setQuery(location.state?.globalSearch);
    }
  }, [location.state]);

  let [startDate, endDate] = selectedDate;

  if (startDate) {
    startDate = moment(startDate).format("YYYY-MM-DD");
  }

  if (endDate) {
    endDate = moment(endDate).format("YYYY-MM-DD");
  }

  const handleDateChange = (selectedDates) => {
    if (selectedDates[1] !== undefined) {
      setSelectedDate(selectedDates);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: "",
      description: "",
      priority: "",
      ticket: null,
      image: null,
      department_id: "",
    },

    validationSchema: Yup.object().shape({
      title: Yup.string()
        .min(
          VALIDATION_MESSAGE.TITLE.MIN_LENGTH,
          VALIDATION_MESSAGE.TITLE.MIN_LENGTH_MESSAGE
        )
        .max(
          VALIDATION_MESSAGE.TITLE.MAX_LENGTH,
          VALIDATION_MESSAGE.TITLE.MAX_LENGTH_MESSAGE
        )
        .required(VALIDATION_MESSAGE.TITLE.REQUIRED_TITLE_MESSAGE),
      description: Yup.string()
        .min(10, "Description must be a 10 character long")
        .max(
          VALIDATION_MESSAGE.DESCRIPTION.MAX_LENGTH,
          VALIDATION_MESSAGE.DESCRIPTION.MAX_LENGTH_MESSAGE
        )
        .required("Please enter the description."),
      priority: Yup.string().required("Please select the priority."),
      department_id: Yup.string().required("Please select website category."),
      image: Yup.mixed()
        .nullable()
        .test(
          "fileSize",
          "File size is too large, file should be max 1MB.",
          (value) => {
            return !value || (value && value.size <= 1048576); // 10 MB in bytes
          }
        )
        .test(
          "fileType",
          "Invalid file type.  Only JPEG, JPG, PNG files are allowed. Please upload a file with one of these formats.",
          (value) => {
            return (
              !value ||
              (value &&
                ["image/jpeg", "image/jpg", "image/png"].includes(value.type))
            );
          }
        ),
    }),

    onSubmit: (values) => {
      addTicket(values);
    },
  });

  const handleClose = () => {
    setShow(false);
    formik.resetForm();
    setSelectedOptions("");
  };

  const statusOptions = [
    { label: "All", value: "All" },
    { label: "Close", value: "Closed" },
    { label: "Active", value: "Active" },
    { label: "Pending", value: "Pending" },
    { label: "Hold", value: "Hold" },
  ];

  const projectOptions = [
    { label: "High", value: "High" },
    { label: "Medium", value: "Medium" },
    { label: "Low", value: "Low" },
  ];

  const getData = async (
    assetID,
    searchParams,
    status,
    startDate,
    endDate,
    selectedOption
  ) => {
    if (assetID) {
      try {
        let params = searchParams || "";

        let apiUrl = `${process.env.REACT_APP_BASE_URL}Newticketing-system/get-tickets?searchFilter=${params}&asset_id=${assetID}`;

        if (status && status !== "All") {
          apiUrl += `&status=${status}`;
        }
        if (startDate && endDate) {
          apiUrl += `&startDate=${startDate}&endDate=${endDate}`;
        }
        if (selectedOption) {
          apiUrl += `&department_id=${selectedOption?.id}`;
        }
        const response = await axios.get(apiUrl);

        if (response.code === 403) {
          navigate("/auth-404-cover");
        }

        const responseData = common.decrypt(response.data);
        if (response.status === "success" && responseData) {
          setData(responseData);
          setIsLoading(false);
        }

        if (response.status === "fail") {
          setData([]);
          setTotalRows(0);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);

        return null;
      }
    } else {
      try {
        let params = searchParams || "";

        let apiUrl = `${process.env.REACT_APP_BASE_URL}Newticketing-system/get-tickets?searchFilter=${params}`;

        if (status && status !== "All") {
          apiUrl += `&status=${status}`;
        }

        if (selectedOption) {
          apiUrl += `&department_id=${selectedOption?.id}`;
        }

        if (startDate && endDate) {
          apiUrl += `&startDate=${startDate}&endDate=${endDate}`;
        }

        const response = await axios.get(apiUrl);

        if (response.code === 403) {
          navigate("/auth-404-cover");
        }

        const responseData = common.decrypt(response.data);
        if (response.status === "success" && responseData) {
          setData(responseData);
          setIsLoading(false);
        }

        if (response.status === "fail") {
          setData([]);
          setTotalRows(0);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);

        return null;
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const debouncedFetchData = _.debounce(() => {
      getData(assetID, query, homeStatus, startDate, endDate, selectedOption);
    }, 500);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ticketShow,
    assetID,
    currentPage,
    rowsPerPage,
    homeStatus,
    query,
    startDate,
    endDate,
    selectedOption,
  ]);

  const addTicket = async (values) => {
    setAddLoading(true);
    try {
      const formData = new FormData();
      if (assetID) {
        if (values.image && values.image.constructor === File) {
          formData.append("ticket-image", values.image);
          formData.append("original_name", values.image.name);
        } else {
          formData.append("original_name", null);
        }
        formData.append("title", values.title);
        formData.append("description", values.description);
        formData.append("priority", values.priority);
        formData.append("assets_id", assetID);
        formData.append("department_id", values.department_id);
      } else {
        if (values.image && values.image.constructor === File) {
          formData.append("ticket-image", values.image);
          formData.append("original_name", values.image.name);
        } else {
          formData.append("original_name", null);
        }
        formData.append("title", values.title);
        formData.append("description", values.description);
        formData.append("priority", values.priority);
        formData.append("department_id", values.department_id);
      }

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}Newticketing-system/Add-ticket`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      };

      const ticketAddedResult = await axios.request(config);

      if (ticketAddedResult.code === 403) {
        navigate("/auth-404-cover");
      }

      if (ticketAddedResult.status === "success" && ticketAddedResult.data) {
        setShow(false);
        formik.resetForm();
        setSelectedOptions("");
        ToastMessage.Success("Ticket has been successfully added");
        setAddLoading(false);
        if (assetID) {
          getData(assetID);
          navigate(location.pathname);
          socket.emit("addTicketOnlineAsset");
        } else {
          getData();
          navigate("/ticketing-system");
          socket.emit("addTicket");
        }
      }
    } catch (error) {
      ToastMessage.Error(error);
      setSelectedOptions("");
      formik.resetForm();
      setAddLoading(false);
      return null;
    } finally {
      setAddLoading(false);
    }
  };

  const updateTicketStatus = async (ticket_id, newStatus, rowId) => {
    setIsLoad(rowId);
    try {
      // let ticketID = parseInt(id.slice(1));
      let result = await axios.put(
        `${process.env.REACT_APP_BASE_URL}Newticketing-system/ticketstatus/${ticket_id}`,
        { status: newStatus.toLowerCase() }
      );

      if (result.code === 403) {
        navigate("/auth-404-cover");
      }
      if (result.status === "success") {
        setIsLoad(false);
        ToastMessage.Success(result.message);

        if (assetID) {
          getData(
            assetID,
            query,
            homeStatus,
            startDate,
            endDate,
            selectedOption
          );
        } else {
          getData(
            assetID,
            query,
            homeStatus,
            startDate,
            endDate,
            selectedOption
          );
        }
        socket.emit("updateTicketStatus");
      }
      if (result.status === "fail") {
        setIsLoad(false);
        ToastMessage.Error("An error occurred. Please try again later.");
      }
    } catch (err) {
      setIsLoad(false);
      ToastMessage.Error(err.message);
      return null;
    }
  };

  async function updateTicketPriority(ticket_id, newPriority, rowId) {
    setIsPriorityLoad(rowId);
    try {
      let result = await axios.put(
        `${process.env.REACT_APP_BASE_URL}Newticketing-system/ticketspriority/${ticket_id}`,
        { priority: newPriority }
      );
      if (result.status === "success") {
        setIsPriorityLoad(false);
        ToastMessage.Success("Ticket priority updated successfully.");
        if (assetID) {
          getData(
            assetID,
            query,
            homeStatus,
            startDate,
            endDate,
            selectedOption
          );
        } else {
          getData(
            assetID,
            query,
            homeStatus,
            startDate,
            endDate,
            selectedOption
          );
        }
        socket.emit("updateTicketPriority");
      } else {
        ToastMessage.Error("Failed to update ticket priority.");
      }
    } catch (err) {
      setIsPriorityLoad(false);
      ToastMessage.Error("An error occurred while updating ticket priority.");
      console.error(err);
    }
  }

  const deleteTicket = async (id) => {
    let apiUrl = `${process.env.REACT_APP_BASE_URL}Newticketing-system/delete-ticket/${id}`;
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: apiUrl,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response.code === 403) {
          navigate("/auth-404-cover");
        }
        if (response.status === "success") {
          if (assetID) {
            getData(assetID);
          } else {
            getData();
          }
        }
        if (response.status === "fail") {
          getData();
        }
      })
      .catch((error) => {
        return null;
      });
  };

  const handleDeleteAlert = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-dark mx-2",
        cancelButton: "btn btn-primary mx-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteTicket(id);
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Ticket has been deleted.",
            "success"
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Ticket is safe :)",
            "error"
          );
        }
      });
  };

  const handleReset = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }
    setHomeStatus("All");
    setQuery("");
    if (assetID) {
      getData(assetID);
    }
    setSelectedDate([]);
    setSelectedOption(null);
  };

  const handlePageChange = (changedPage) => {
    setCurrentPage(changedPage);
  };

  const handlePerRowsChange = (recordsPerPage, page) => {
    setRowsPerPage(recordsPerPage);
    setCurrentPage(page);
  };

  const processTicketData = (data) => {
    let statusCounts = {
      active: [],
      inactive: [],
    };

    data.forEach((ticket) => {
      if (ticket.status === "active" || ticket.status === "pending") {
        statusCounts.active.push({
          createdAt: ticket.createdAt,
          status: ticket.status,
        });
      } else if (ticket.status === "hold") {
        statusCounts.inactive.push({
          createdAt: ticket.createdAt,
          status: ticket.status,
        });
      }
    });

    if (typeof setTileBoxTickets === "function") {
      setTileBoxTickets(statusCounts);
    }
  };

  useEffect(() => {
    processTicketData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const fetchWebsiteCategories = async () => {
    try {
      let { status, data } = await axios.get(API_ROUTES.GET_WEBSITE_CATEGORIES);
      const responseData = common.decrypt(data);
      if (status === "success" && responseData) {
        setWebsiteGroupOptions(
          responseData.map((item) => {
            return {
              label: item.category,
              value: item.category,
              id: item.id,
            };
          })
        );
      }
    } catch (err) {
      console.error(err.message);
      setWebsiteGroupOptions([]);
    }
  };

  const getDepartmentName = async (id) => {
    try {
      let response = await axios.get(
        `websiteCategories/get-category-name/${id}`
      );
      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;
      if (response.status === "success") {
        const finalObject = {
          ...response.data,
          value: response.data.id,
        };
        setDefaultSelectedOption(finalObject);
        formik.setFieldValue("department_id", response.data?.id);
      }
    } catch (error) {
      console.error("Error ->", error.message);
    }
  };

  useEffect(() => {
    if (defaultSelectedOption)
      formik.setFieldValue("department_id", defaultSelectedOption?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    fetchWebsiteCategories();
    if (assetID && isAdminUser && assetsData?.department_id) {
      formik.setFieldValue("department_id", assetsData?.department_id);
    }
    if (userData?.department_id) getDepartmentName(userData?.department_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const isAdminUser =
    (userIsAdmin === "Y" && userDepartment === 0) ||
    userData?.role === "Super Admin";

  const isSelectDisabled = !isAdminUser || (isAdminUser && assetID);

  const handleChangeImage = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedFormats = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      const validSize = file.size <= convertMBToBytes(DEFAULT_IMAGE_SIZE);

      if (!allowedFormats.includes(file.type)) {
        formik.setFieldError(
          "image",
          "Invalid file format. Only JPEG, JPG, PNG, and SVG files are allowed."
        );
        event.target.value = null;
      } else if (!validSize) {
        formik.setFieldError(
          "image",
          `Failed to upload an image. The image maximum size is ${DEFAULT_IMAGE_SIZE} MB.`
        );
        event.target.value = null;
      } else {
        formik.setFieldError("image", "");
        formik.setFieldValue("image", file);
      }
    }
  };

  return (
    <>
      <Box className={assetID ? "" : "page-content site-table-container"}>
        <Card className="table-main-card">
          <Box
            className="table-card-header"
            {...(isDashboardView ? dragHandleProps : {})}
            style={{
              cursor: isDashboardView ? "grab" : "default",
            }}
          >
            <Box className="left-side">
              <Box className="search-box">
                <i
                  className="ri-search-line search-icon"
                  style={{ height: "40px" }}
                ></i>
                <input
                  className="form-control search border-0"
                  type="number"
                  placeholder="Search By ID"
                  value={query}
                  onChange={(e) => {
                    setQuery(e.target.value);
                  }}
                />
              </Box>
              <Box className="dateinput">
                <Flatpickr
                  placeholder="Select Date Range"
                  ref={flatpickrRef}
                  className="form-control"
                  options={{
                    dateFormat: `${dateFormat}`,
                    mode: "range",
                  }}
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </Box>
              <Button
                className="btn btn-primary reset-btn"
                onClick={handleReset}
              >
                <LoopIcon />
              </Button>
              <FormControl
                fullWidth
                variant="filled"
                sx={{
                  width: "150px",
                  minWidth: "150px",
                }}
              >
                <Select
                  displayEmpty
                  fullWidth
                  value={
                    statusOptions.find((option) => option.value === homeStatus)
                      ?.value || ""
                  }
                  onChange={(statusOptions, m) => setHomeStatus(m?.props.value)}
                  sx={DropdownStyle}
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {!isDashboardView &&
                (userData?.role === "Super Admin" ||
                  (userIsAdmin === "Y" && userDepartment === 0)) && (
                  <>
                    <FormControl
                      fullWidth
                      variant="filled"
                      sx={{
                        width: "220px",
                      }}
                    >
                      <Autocomplete
                        options={websiteGroupOptions}
                        getOptionLabel={(option) => option.label}
                        value={selectedOption || null}
                        onChange={(event, newValue) =>
                          setSelectedOption(newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Website Category"
                          />
                        )}
                        fullWidth
                        sx={AutoDropdownStyle}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value?.value
                        }
                        disableClearable
                        renderOption={(props, option) => {
                          const { key, ...rest } = props;
                          return (
                            <li
                              key={key}
                              {...rest}
                              style={{ whiteSpace: "break-spaces" }}
                            >
                              {option.label}
                            </li>
                          );
                        }}
                      />
                    </FormControl>
                  </>
                )}
              {hasPermission && hasPermission.canCreate != null ? (
                <>
                  <Button
                    className="btn btn-primary fs-14 btn-sm"
                    sx={{ minWidth: "165px !important" }}
                    onClick={() => setShow(true)}
                  >
                    <AddOutlinedIcon className="me-2" />
                    Raise a ticket
                  </Button>
                </>
              ) : null}
            </Box>
            {isDashboardView && (
              <button
                className="btn btn-primary view-btn"
                onClick={() => navigate("/ticketing-system")}
              >
                View All
              </button>
            )}
          </Box>
          <Card.Body>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <Spinner height={100} width={100} />
              </div>
            ) : (
              <TicketingSystemTable
                assetID={assetID}
                ticketsData={data}
                isDashboardView={isDashboardView}
                paginationDisable={paginationDisable}
                updateTicketStatus={updateTicketStatus}
                updateTicketPriority={updateTicketPriority}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                deleteTicket={deleteTicket}
                handleDeleteAlert={handleDeleteAlert}
                totalRows={totalRows}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                isLoad={isLoad}
                isPriorityLoad={isPriorityLoad}
              />
            )}

            <Box className="noresult" style={{ display: "none" }}>
              <Box className="text-center">
                <lord-icon
                  src="https://cdn.lordicon.com/msoeawqm.json"
                  trigger="loop"
                  colors="primary:#25a0e2,secondary:#00bd9d"
                  style={{ width: "75px", height: "75px" }}
                ></lord-icon>
                <h5 className="mt-2">Sorry! No Result Found</h5>
                <p className="text-muted mb-0">
                  We've searched more than 200k+ tasks We did not find any tasks
                  for you search.
                </p>
              </Box>
            </Box>
          </Card.Body>
        </Card>
      </Box>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        backdrop="static"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>Create a new ticket</Offcanvas.Title>
          <CloseButton onClick={handleClose} />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Box className="mb-3">
              <TextField
                fullWidth
                label="Title *"
                id="outlined-size-small"
                name="title"
                placeholder="Enter the ticket title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.title && formik.touched.title ? (
                <span className="error-text">{formik.errors.title}</span>
              ) : null}
            </Box>
            <Box className="mb-3">
              <TextField
                fullWidth
                id="description"
                name="description"
                label="Description *"
                placeholder="Enter Short description"
                multiline
                rows={2}
                variant="outlined"
                value={formik.values.description}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.errors.description && formik.touched.description && (
                <span className="error-text">{formik.errors.description}</span>
              )}
            </Box>
            <Box className=" mb-3">
              <FormControl fullWidth>
                <InputLabel>Website category *</InputLabel>
                <Select
                  label="Website category *"
                  fullWidth
                  id="department_id"
                  name="department_id"
                  value={
                    !isAdminUser
                      ? defaultSelectedOption?.id
                      : assetID && assetsData?.department_id
                      ? assetsData?.department_id
                      : formik.values.department_id || ""
                  }
                  onChange={(event) => {
                    const selectedValue = event.target.value;

                    formik.setFieldValue("department_id", selectedValue);
                  }}
                  MenuProps={MenuHeight}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <>Website category</>;
                    }
                    return websiteGroupOptions.find(
                      (option) => option.id === selected
                    )?.label;
                  }}
                  disabled={isSelectDisabled}
                >
                  {websiteGroupOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formik.errors.department_id && formik.touched.department_id && (
                <span className="error-text">
                  {formik.errors.department_id}
                </span>
              )}
            </Box>

            <Box className="mb-3">
              <FormControl fullWidth>
                <InputLabel>Priority *</InputLabel>
                <Select
                  label="Priority *"
                  id="priority"
                  name="priority"
                  value={selectedOptions}
                  onChange={(event) => {
                    const selectedValue = event.target.value;
                    formik.setFieldValue("priority", selectedValue);
                    setSelectedOptions(selectedValue);
                  }}
                  onBlur={formik.handleBlur}
                  renderValue={(selectedValue) => {
                    if (selectedValue === "") {
                      return <em>Select an option</em>;
                    }
                    return selectedValue;
                  }}
                >
                  <MenuItem disabled value="">
                    <em>Select an option</em>
                  </MenuItem>
                  {projectOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {formik.errors.priority && formik.touched.priority ? (
                  <span className="error-text">{formik.errors.priority}</span>
                ) : null}
              </FormControl>
            </Box>
            <Box className="mb-3">
              <label htmlFor="image" className="d-flex align-items-center">
                Upload Image
                <Tooltip title={LOGOTOOLTIP} placement="top-start" arrow>
                  <i
                    className="ri-information-line fs-18 cursor-pointer ms-2"
                    style={{
                      color: "#ced4da",
                    }}
                  ></i>
                </Tooltip>
              </label>
              <Input
                className="form-control"
                type="file"
                id="image"
                name="image"
                accept="image/*"
                onChange={(event) => {
                  handleChangeImage(event);
                  formik.setFieldTouched("image", true, false);
                }}
                onBlur={formik.handleBlur}
                invalid={formik.errors.image && formik.touched.image}
              />
              {formik.errors.image && formik.touched.image && (
                <FormFeedback type="invalid" style={{ color: "red" }}>
                  {formik.errors.image}
                </FormFeedback>
              )}
            </Box>
            <Box className="d-flex align-items-center mt-4">
              <Box>
                <Button
                  type="submit"
                  className="btn btn-dark"
                  disabled={isAddLoading}
                >
                  {isAddLoading ? (
                    <Spinner size="sm" variant="light" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
              <Box style={{ paddingLeft: "10px" }}>
                <Button className="btn btn-primary" onClick={handleClose}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal id="deleteRecordModal" tabIndex="-1" aria-hidden="true">
        <Modal.Header>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="btn-close"
          ></button>
        </Modal.Header>
        <Modal.Body>
          <Box className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#25a0e2,secondary:#00bd9d"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <Box className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you sure you want to remove this user ?
              </p>
            </Box>
          </Box>
          <Box className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-primary"
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </Box>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TicketingSystem;
