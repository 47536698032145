import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import {
  addGeneralSettingData,
  addSettingsData,
} from "../../store/settings/action";
import { TextField, Button, Box, Tooltip } from "@mui/material";
import { VisuallyHiddenInput } from "../NewDashboard/constant";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import common, {
  convertMBToBytes,
  defaultImageSizeTooltip,
} from "../../Components/Common/common";
import { Spinner } from "reactstrap";
import { useDefaultImageSize } from "../../Components/Hooks/UseDeafultImageSizeHook";
import { getAuthToken } from "../../Api/common";

const General = () => {
  const token = getAuthToken();
  const dispatch = useDispatch();
  const DEFAULT_IMAGE_SIZE = useDefaultImageSize();
  const DEFAULT_IMAGE_SIZE_TOOLTIP =
    defaultImageSizeTooltip(DEFAULT_IMAGE_SIZE);

  const intialValues = {
    SITE_NAME: "",
    TIMEZONE: "",
    DEFAULT_CONTACTUS_EMAIL: "",
    DEFAULT_SUBMIT_TICKET_EMAIL: "",
    FRONT_LOGO_ID: null,
    SMALL_LOGO_ID: null,
  };

  const navigate = useNavigate();
  const values = intialValues;

  const [uploadedFile, setUploadedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedFormats = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      const validSize = file.size <= convertMBToBytes(DEFAULT_IMAGE_SIZE);

      if (!allowedFormats.includes(file.type)) {
        toast.error(
          "Invalid file format. Only JPEG, JPG, PNG, and SVG files are allowed."
        );
      } else if (!validSize) {
        toast.error(
          `Failed to upload an image. The image maximum size is ${DEFAULT_IMAGE_SIZE} MB.`
        );
      } else {
        formik.setFieldValue("FRONT_LOGO_ID", file);

        // Read file as data URL
        const reader = new FileReader();
        reader.onload = (e) => {
          setUploadedFile(e.target.result); // Assuming setUploadedFile sets the uploaded file as a data URL
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const PostData = async (values) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("SITE_NAME", values.SITE_NAME);
      formData.append(
        "DEFAULT_CONTACTUS_EMAIL",
        values.DEFAULT_CONTACTUS_EMAIL
      );

      formData.append(
        "DEFAULT_SUBMIT_TICKET_EMAIL",
        values.DEFAULT_SUBMIT_TICKET_EMAIL
      );

      if (values.FRONT_LOGO_ID) {
        formData.append("FRONT_LOGO_ID", values.FRONT_LOGO_ID);
      }

      if (values.SMALL_LOGO_ID) {
        formData.append("SMALL_LOGO_ID", values.SMALL_LOGO_ID);
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}settings/add-settings`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      const responseData = await response.json();

      let newResponse = [];
      for (const res of responseData?.data) {
        newResponse.push(res?.updatedField);
      }
      dispatch(addGeneralSettingData(newResponse));

      if (responseData.code === 403) {
        navigate("/auth-404-cover");
      }

      if (responseData.code === "200") {
        toast.success(responseData.message);
      }
    } catch (error) {
      console.error("Error during request processing:", error);
      setIsLoading(false);
      toast.error(
        "An error occurred while processing your request. Please try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    SITE_NAME: Yup.string().required("Please enter the sitename"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: values,

    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await PostData(values);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const getSettings = useCallback(async () => {
    try {
      let url = `${process.env.REACT_APP_BASE_URL}settings/get-settings`;
      let reqData = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let result = await fetch(url, reqData);
      let response = await result.json();

      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;

      if (response.code === 403) {
        navigate("/auth-404-cover");
      }

      if (response.code === "200") {
        const fieldValuesMap = {};
        response.data.rows.forEach((row) => {
          fieldValuesMap[row.field_name] = row.field_value;
        });

        const filterData = response.data.rows.filter(
          (item) => item.settings_category === "general"
        );

        dispatch(addSettingsData(response.data.rows));
        dispatch(addGeneralSettingData(filterData));

        formik.setValues({
          ...formik.values,
          SITE_NAME: fieldValuesMap["SITE_NAME"] || "",
          DEFAULT_CONTACTUS_EMAIL:
            fieldValuesMap["DEFAULT_CONTACTUS_EMAIL"] || "",
          DEFAULT_SUBMIT_TICKET_EMAIL:
            fieldValuesMap["DEFAULT_SUBMIT_TICKET_EMAIL"] || "",
          FRONT_LOGO_ID: fieldValuesMap["FRONT_LOGO_ID"] || "",
          SMALL_LOGO_ID: fieldValuesMap["SMALL_LOGO_ID"] || "",
        });

        if (fieldValuesMap["FRONT_LOGO_ID"]) {
          const logoUrl = `${process.env.REACT_APP_BASE_URL}${fieldValuesMap["FRONT_LOGO_ID"]}`;
          setUploadedFile(logoUrl);
        }
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("An error occurred while fetching API key.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, token]);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <React.Fragment>
      <Card>
        <Card.Body className="setting-card-body">
          <form noValidate onSubmit={formik.handleSubmit}>
            <Row className="g-4">
              <Col md={6}>
                <TextField
                  required
                  fullWidth
                  label="Site Name"
                  placeholder="Enter Site Name"
                  name="SITE_NAME"
                  value={formik.values.SITE_NAME}
                  {...formik.getFieldProps("SITE_NAME")}
                />
                {formik.errors.SITE_NAME && formik.touched.SITE_NAME && (
                  <span className="error-text">{formik.errors.SITE_NAME}</span>
                )}
              </Col>
              <Col md={6}>
                <TextField
                  fullWidth
                  label="Default Contact Us Email"
                  placeholder="Default Contact Us Email"
                  name="DEFAULT_CONTACTUS_EMAIL"
                  type="email"
                  value={formik.values.DEFAULT_CONTACTUS_EMAIL}
                  {...formik.getFieldProps("DEFAULT_CONTACTUS_EMAIL")}
                />
              </Col>
              <Col md={6}>
                <TextField
                  fullWidth
                  label="Default Submit a Ticket Email"
                  placeholder="Default Submit a Ticket Email"
                  name="DEFAULT_SUBMIT_TICKET_EMAIL"
                  type="email"
                  value={formik.values.DEFAULT_SUBMIT_TICKET_EMAIL}
                  {...formik.getFieldProps("DEFAULT_SUBMIT_TICKET_EMAIL")}
                />
              </Col>
              <Col md={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  <Button
                    component="label"
                    role={undefined}
                    className="btn btn-outline-primary"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload file
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                  <Tooltip
                    title={
                      <>
                        Upload a file to update your website logo. <br />
                        {DEFAULT_IMAGE_SIZE_TOOLTIP}
                      </>
                    }
                    placement="top-start"
                    arrow
                  >
                    <i
                      className="ri-information-line fs-18 cursor-pointer ms-2"
                      style={{
                        color: "#ced4da",
                      }}
                    ></i>
                  </Tooltip>
                  {uploadedFile && (
                    <Box
                      sx={{
                        background: "#f8f8f8",
                        padding: "5px",
                        width: "200px",
                        height: "50px",
                      }}
                    >
                      <img
                        src={uploadedFile}
                        alt="Uploaded"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Col>

              <Col sm={12}>
                <Button
                  className="btn btn-primary ms-auto d-block"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner size="sm" variant="light" />
                  ) : (
                    <>Save &amp; Keep Editing</>
                  )}
                </Button>
              </Col>
            </Row>
          </form>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default General;
