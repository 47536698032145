import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Card, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TextField } from "@mui/material";
import { useState } from "react";
import { Spinner } from "reactstrap";
import { getAuthToken } from "../../Api/common";

const ApiKey = () => {
  const token = getAuthToken();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    apiValue: Yup.string().required("API Key is required"),
    gtMetrixEmail: Yup.string().required("GT METRIX Email is required"),
    gtMetrixApiKey: Yup.string().required("GT METRIX API Key is required"),
    immuniWebApiKey: Yup.string().required("IMMUNI WEB API Key is required"),
    ImmuniWebMaxHitLimit: Yup.string().required(
      "IMMUNI WEB max hit limit is required"
    ),
    googleAnalyticMaxHitLimit: Yup.string().required(
      "Google Analytics max hit limit is required"
    ),
    gtMetrixMaxHitLimit: Yup.string().required(
      "Gt Metrix max hit limit is required"
    ),
    uptimeRobotMaxHitLimit: Yup.string().required(
      "Upitme robot max hit limit is required"
    ),
    googleAnalyticsClientEmail: Yup.string().required(
      "GOOGLE ANALYTICS CLIENT E-MAIL is required"
    ),
    googleAnalyticsPrivateKey: Yup.string().required(
      "GOOGLE ANALYTICS PRIVATE Key is required"
    ),
    websiteStatusDownEmail: Yup.string().required(
      "WEBISTE STATUS DOWN EMAIL is required"
    ),
    apiFailedLimit: Yup.string().required("API FAILED LIMIT is required"),
  });

  const formik = useFormik({
    initialValues: {
      apiValue: "",
      gtMetrixEmail: "",
      gtMetrixApiKey: "",
      immuniWebApiKey: "",
      googleAnalyticsClientEmail: "",
      googleAnalyticsPrivateKey: "",
      websiteStatusDownEmail: "",
      uptimeRobotMaxHitLimit: "",
      gtMetrixMaxHitLimit: "",
      googleAnalyticMaxHitLimit: "",
      ImmuniWebMaxHitLimit: "",
      apiFailedLimit: "",
    },

    validationSchema: validationSchema,

    onSubmit: async (values) => {
      try {
        await postApiKey(values);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const postApiKey = async ({
    apiValue,
    gtMetrixEmail,
    gtMetrixApiKey,
    immuniWebApiKey,
    googleAnalyticsClientEmail,
    googleAnalyticsPrivateKey,
    websiteStatusDownEmail,
    ImmuniWebMaxHitLimit,
    gtMetrixMaxHitLimit,
    uptimeRobotMaxHitLimit,
    googleAnalyticMaxHitLimit,
    apiFailedLimit,
  }) => {
    try {
      setIsLoading(true);
      let url = `${process.env.REACT_APP_BASE_URL}settings/add-apikeys`;
      let reqData = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          UPTIME_ROBOT_MAIN_API_KEY: apiValue,
          GT_METRIX_EMAIL: gtMetrixEmail,
          GT_METRIX_API_KEY: gtMetrixApiKey,
          IMMUNI_WEB_API_KEY: immuniWebApiKey,
          GOOGLE_ANALYTICS_CLIENT_EMAIL: googleAnalyticsClientEmail,
          GOOGLE_ANALYTICS_PRIVATE_KEY: googleAnalyticsPrivateKey,
          WEBSITE_STATUS_DOWN_EMAIL: websiteStatusDownEmail,
          GT_METRIX_MAX_HIT_LIMIT: gtMetrixMaxHitLimit,
          UPTIME_ROBOT_MAX_HIT_LIMIT: uptimeRobotMaxHitLimit,
          IMMUNI_WEB_MAX_HIT_LIMIT: ImmuniWebMaxHitLimit,
          GOOGLE_ANALYTIC_MAX_HIT_LIMIT: googleAnalyticMaxHitLimit,
          API_FAILED_LIMIT: apiFailedLimit,
        }),
      };
      let result = await fetch(url, reqData);
      let response = await result.json();
      if (response.code === 403) {
        console.error("Authorization failed");
        navigate("/auth-404-cover");
      }
      if (response.code === "200") {
        toast.success(response.message);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      toast.error("An error occurred while processing your request.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchApiKey = useCallback(async () => {
    try {
      let url = `${process.env.REACT_APP_BASE_URL}settings/get-apikeys`;
      let reqData = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let result = await fetch(url, reqData);
      let response = await result.json();
      if (response.code === 403) {
        navigate("/auth-404-cover");
      }
      if (response.code === "200") {
        formik.setFieldValue(
          "apiValue",
          response.data?.find(
            (a) => a.field_name === "UPTIME_ROBOT_MAIN_API_KEY"
          ).field_value
        );
        formik.setFieldValue(
          "gtMetrixEmail",
          response.data?.find((a) => a.field_name === "GT_METRIX_EMAIL")
            .field_value
        );
        formik.setFieldValue(
          "gtMetrixApiKey",
          response.data?.find((a) => a.field_name === "GT_METRIX_API_KEY")
            .field_value
        );
        formik.setFieldValue(
          "immuniWebApiKey",
          response.data?.find((a) => a.field_name === "IMMUNI_WEB_API_KEY")
            .field_value
        );
        formik.setFieldValue(
          "googleAnalyticsClientEmail",
          response.data?.find(
            (a) => a.field_name === "GOOGLE_ANALYTICS_CLIENT_EMAIL"
          ).field_value
        );
        formik.setFieldValue(
          "googleAnalyticsPrivateKey",
          response.data?.find(
            (a) => a.field_name === "GOOGLE_ANALYTICS_PRIVATE_KEY"
          ).field_value
        );
        formik.setFieldValue(
          "websiteStatusDownEmail",
          response.data?.find(
            (a) => a.field_name === "WEBSITE_STATUS_DOWN_EMAIL"
          ).field_value
        );
        formik.setFieldValue(
          "gtMetrixMaxHitLimit",
          response.data?.find((a) => a.field_name === "GT_METRIX_MAX_HIT_LIMIT")
            .field_value
        );
        formik.setFieldValue(
          "ImmuniWebMaxHitLimit",
          response.data?.find(
            (a) => a.field_name === "IMMUNI_WEB_MAX_HIT_LIMIT"
          ).field_value
        );
        formik.setFieldValue(
          "uptimeRobotMaxHitLimit",
          response.data?.find(
            (a) => a.field_name === "UPTIME_ROBOT_MAX_HIT_LIMIT"
          ).field_value
        );
        formik.setFieldValue(
          "googleAnalyticMaxHitLimit",
          response.data?.find(
            (a) => a.field_name === "GOOGLE_ANALYTIC_MAX_HIT_LIMIT"
          ).field_value
        );
        formik.setFieldValue(
          "apiFailedLimit",
          response.data?.find((a) => a.field_name === "API_FAILED_LIMIT")
            .field_value
        );
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, token]);

  useEffect(() => {
    fetchApiKey();
  }, [fetchApiKey]);

  return (
    <React.Fragment>
      <Card>
        <Card.Body className="setting-card-body">
          <form onSubmit={formik.handleSubmit}>
            <Row className="g-4">
              <Col md={6}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  label="API Key"
                  placeholder="Enter API Key"
                  name="apiValue"
                  value={formik.values.apiValue}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={formik.touched.apiValue && !!formik.errors.apiValue}
                />
                {formik.touched.apiValue && (
                  <span className="error-text">{formik.errors.apiValue}</span>
                )}
              </Col>

              <Col md={6}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  label="gt-metrix-email"
                  placeholder="Enter GT METRIX EMAIL"
                  name="gtMetrixEmail"
                  value={formik.values.gtMetrixEmail}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    formik.touched.gtMetrixEmail &&
                    !!formik.errors.gtMetrixEmail
                  }
                />
                {formik.touched.gtMetrixEmail && (
                  <div className="text-danger">
                    {formik.errors.gtMetrixEmail}
                  </div>
                )}
              </Col>

              <Col md={6}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  label="GT METRIX API Key"
                  placeholder="Enter GT METRIX API Key"
                  name="gtMetrixApiKey"
                  value={formik.values.gtMetrixApiKey}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    formik.touched.gtMetrixApiKey &&
                    !!formik.errors.gtMetrixApiKey
                  }
                />
                {formik.touched.gtMetrixApiKey && (
                  <div className="text-danger">
                    {formik.errors.gtMetrixApiKey}
                  </div>
                )}
              </Col>

              <Col md={6}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  label="IMMUNI WEB API Key"
                  placeholder="Enter IMMUNI WEB API Key"
                  name="immuniWebApiKey"
                  value={formik.values.immuniWebApiKey}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    formik.touched.immuniWebApiKey &&
                    !!formik.errors.immuniWebApiKey
                  }
                />
                {formik.touched.immuniWebApiKey && (
                  <div className="text-danger">
                    {formik.errors.immuniWebApiKey}
                  </div>
                )}
              </Col>
              <Col md={6}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  label="GOOGLE ANALYTICS CLIENT E-MAIL"
                  placeholder="Enter GOOGLE ANALYTICS CLIENT E-MAIL"
                  name="googleAnalyticsClientEmail"
                  value={formik.values.googleAnalyticsClientEmail}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    formik.touched.googleAnalyticsClientEmail &&
                    !!formik.errors.googleAnalyticsClientEmail
                  }
                />
                {formik.touched.googleAnalyticsClientEmail && (
                  <div className="text-danger">
                    {formik.errors.googleAnalyticsClientEmail}
                  </div>
                )}
              </Col>

              <Col md={6}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  label="API ERROR LOG EMAIL"
                  placeholder="Enter WEBSITE STATUS DOWN REPORT E-MAIL"
                  name="websiteStatusDownEmail"
                  value={formik.values.websiteStatusDownEmail}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    formik.touched.websiteStatusDownEmail &&
                    !!formik.errors.websiteStatusDownEmail
                  }
                />
                {formik.touched.websiteStatusDownEmail && (
                  <div className="text-danger">
                    {formik.errors.websiteStatusDownEmail}
                  </div>
                )}
              </Col>

              <Col md={6}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  label="API FAILED LIMIT"
                  placeholder="Enter API FAILED LIMIT"
                  name="apiFailedLimit"
                  value={formik.values.apiFailedLimit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    formik.touched.apiFailedLimit &&
                    !!formik.errors.apiFailedLimit
                  }
                />
                {formik.touched.apiFailedLimit && (
                  <div className="text-danger">
                    {formik.errors.apiFailedLimit}
                  </div>
                )}
              </Col>

              <Col md={6}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  label="Immuni Web API Limit"
                  placeholder="Enter immuni web max hit limit."
                  name="ImmuniWebMaxHitLimit"
                  value={formik.values.ImmuniWebMaxHitLimit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    formik.touched.ImmuniWebMaxHitLimit &&
                    !!formik.errors.ImmuniWebMaxHitLimit
                  }
                />
                {formik.touched.ImmuniWebMaxHitLimit && (
                  <div className="text-danger">
                    {formik.errors.ImmuniWebMaxHitLimit}
                  </div>
                )}
              </Col>
              <Col md={6}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  label="Google Analytics API Limit"
                  placeholder="Enter Google Analytics Max Hit Limit"
                  name="googleAnalyticMaxHitLimit"
                  value={formik.values.googleAnalyticMaxHitLimit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    formik.touched.googleAnalyticMaxHitLimit &&
                    !!formik.errors.googleAnalyticMaxHitLimit
                  }
                />
                {formik.touched.googleAnalyticMaxHitLimit && (
                  <div className="text-danger">
                    {formik.errors.googleAnalyticMaxHitLimit}
                  </div>
                )}
              </Col>
              <Col md={6}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  label="UPTIME ROBOT API LIMIT"
                  placeholder="Enter UPTIME ROBOT MAX HIT LIMIT"
                  name="uptimeRobotMaxHitLimit"
                  value={formik.values.uptimeRobotMaxHitLimit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    formik.touched.uptimeRobotMaxHitLimit &&
                    !!formik.errors.uptimeRobotMaxHitLimit
                  }
                />
                {formik.touched.uptimeRobotMaxHitLimit && (
                  <div className="text-danger">
                    {formik.errors.uptimeRobotMaxHitLimit}
                  </div>
                )}
              </Col>
              <Col md={6}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  label="Gt Metrix API limit"
                  placeholder="Enter Gt Metrix max hit limit"
                  name="gtMetrixMaxHitLimit"
                  value={formik.values.gtMetrixMaxHitLimit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    formik.touched.gtMetrixMaxHitLimit &&
                    !!formik.errors.gtMetrixMaxHitLimit
                  }
                />
                {formik.touched.gtMetrixMaxHitLimit && (
                  <div className="text-danger">
                    {formik.errors.gtMetrixMaxHitLimit}
                  </div>
                )}
              </Col>
              <Col lg={12}>
                <TextField
                  multiline
                  fullWidth
                  rows={10}
                  id="outlined-multiline-static"
                  label="GOOGLE ANALYTICS PRIVATE KEY"
                  placeholder="Enter GOOGLE ANALYTICS PRIVATE KEY"
                  name="googleAnalyticsPrivateKey"
                  type="text"
                  value={formik.values.googleAnalyticsPrivateKey}
                  {...formik.getFieldProps("googleAnalyticsPrivateKey")}
                />
                {formik.touched.googleAnalyticsPrivateKey && (
                  <div className="text-danger">
                    {formik.errors.googleAnalyticsPrivateKey}
                  </div>
                )}
              </Col>
              <Col sm={12}>
                <Button
                  className="btn btn-primary ms-auto d-block"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner size="sm" variant="light" />
                  ) : (
                    <>Save &amp; Keep Editing</>
                  )}
                </Button>
              </Col>
            </Row>
          </form>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default ApiKey;
