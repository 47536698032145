import axios from "axios";
import { GET_PURCHASE_STATUS_OF_ADMIN } from "./ApiRoutes";
import { decryptResponse } from "../Components/Common/common";

export const getPurchaseStausOfAdmin = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(GET_PURCHASE_STATUS_OF_ADMIN)
      .then((response) => {
        const decryptedResponse = decryptResponse(response?.data);
        resolve(decryptedResponse);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
