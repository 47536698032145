import { API_ROUTES } from "../../ApiRoutes";
import { getAuthToken } from "../../common";


export const WebsiteCategoriesListReq = () => {
    const authToken = getAuthToken();
    let url = API_ROUTES.GET_WEBSITE_CATEGORIES;
    let reqData = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return { url, reqData };

}
