import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  Modal,
  Form,
  Button,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import axios from "axios";
import { ToastMessage } from "../../../../Components/Common/ToastMessage";
import { ToastContainer } from "react-toastify";
import Loader from "../../../../Components/Common/Loader";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import Choices from "choices.js";
import "choices.js/public/assets/styles/choices.min.css";
import { formatDateTime } from "../../../../Components/Common/FormatDateTIme";
import {
  fetchOnlineAssetsReq,
  fetchSecurityHistoryReq,
} from "../../../../Api/NewDashboard/DashboardAnalyticTab/DvdlTabsReq";
import {
  fetchOnlineAssetsRes,
  fetchSecurityHistoryRes,
} from "../../../../Api/NewDashboard/DashboardAnalyticTab/DvdlTabsRes";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import {
  DownloadIcon,
  PrintIcon,
  ShareIcon,
} from "../../../../Components/Common/SvgIcons";
import PDFDocument from "../WebsiteReport/PDFDocument";
import { API_ROUTES } from "../../../../Api/ApiRoutes";
import { Spinner } from "reactstrap";
import common from "../../../../Components/Common/common";
import { getAuthToken, getUserId } from "../../../../Api/common";

const WebsiteSecurityHistory = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const choicesRef = React.useRef(null);
  const userId = getUserId();

  let token = getAuthToken();
  const [reportLoading, setReportLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");
  const [reportId, setReportId] = useState(null);
  const [securityHistory, setSecurityHistory] = useState([]);
  const [securityHistoryCount, setSecurityHistoryCount] = useState(null);

  const [assetsData, setAssetsData] = useState("");
  const fetchOnlineAssets = useCallback(async () => {
    try {
      const { url, userData } = fetchOnlineAssetsReq({ params });

      let result = await fetch(url, userData);
      let response = await result.json();

      fetchOnlineAssetsRes({
        response,
        setAssetsData,
        navigate,
      });
    } catch (error) {
      console.error(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, navigate]);
  useEffect(() => {
    fetchOnlineAssets();
  }, [fetchOnlineAssets]);

  const fetchSecurityHistory = useCallback(async () => {
    try {
      setLoading(true);
      const { url, userData } = fetchSecurityHistoryReq({
        params,
        userId,
      });

      let schedule = await fetch(url, userData);
      let response = await schedule.json();

      fetchSecurityHistoryRes({
        response,
        setSecurityHistory,
        setSecurityHistoryCount,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, userId, token]);

  const testedLength = securityHistoryCount || 0;
  const testCount = testedLength ?? "-";

  useEffect(() => {
    fetchSecurityHistory();
  }, [fetchSecurityHistory]);

  const handleCloseInput = () => {
    setError("");
    setShow(false);
  };

  const handleShowInput = (reportId, id) => {
    setShow(true);
    setReportId(reportId);
  };

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;

  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  const renderTooltip = (text) => (
    <Tooltip id={`tooltip-${text}`}>{text}</Tooltip>
  );

  const columns = [
    {
      name: "Scheduled Date",
      cell: (row) => {
        const dateTime = formatDateTime(
          row.schedule_date_time,
          dateFormat,
          timeZone,
          timeFormat
        );
        return <>{dateTime.timeZone.formattedDateFinal || "-"}</>;
      },
      selector: (row) => row.schedule_date_time,
      grow: 1.5,
    },
    {
      name: "Time",
      cell: (row) => {
        const dateTime = formatDateTime(
          row.schedule_date_time,
          dateFormat,
          timeZone,
          timeFormat
        );
        return <>{dateTime.timeZone.formattedTimed || "-"}</>;
      },
      selector: (row) => row.schedule_date_time,
    },
    {
      name: "Done By",
      selector: (row) => row.tested_by || "-",
      sortable: true,
      grow: 1.5,
    },
    {
      name: "Completed Date",
      cell: (row) => {
        const completedDateTime = formatDateTime(
          row.completed_date_time,
          dateFormat,
          timeZone,
          timeFormat
        );
        return <>{completedDateTime.timeZone.formattedDateFinal || "-"}</>;
      },
      selector: (row) => row.completed_date_time,
    },
    {
      name: "Time",
      cell: (row) => {
        const completedDateTime = formatDateTime(
          row.completed_date_time,
          dateFormat,
          timeZone,
          timeFormat
        );
        return <>{completedDateTime.timeZone.formattedTimed || "-"}</>;
      },
      selector: (row) => row.contact_no,
    },
    {
      name: "Report",
      cell: (row) => {
        return (
          <>
            <Box
              className="report-table-menu"
              sx={{ gap: "25px", display: "inline-flex" }}
            >
              {reportLoading !== null && reportLoading === row.id ? (
                <Loader style={{ height: "25px", width: "25px" }} />
              ) : (
                <>
                  {row.report_id !== null ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={renderTooltip("Download Report")}
                    >
                      <Link
                        onClick={() =>
                          sendReport({ report_id: row.report_id, id: row.id })
                        }
                      >
                        <DownloadIcon opacity="1" />
                      </Link>
                    </OverlayTrigger>
                  ) : (
                    <DownloadIcon opacity="0.5" />
                  )}
                </>
              )}

              <span>
                {row.report_id !== null ? (
                  <>
                    <span
                      className="cursor-pointer"
                      onClick={() => window.print()}
                    >
                      <PrintIcon opacity="1" />
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <PrintIcon onClick={() => window.print()} opacity="0.5" />
                    </span>
                  </>
                )}
              </span>

              {row.report_id !== null ? (
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Share Report")}
                >
                  <Link onClick={() => handleShowInput(row.report_id, row.id)}>
                    <ShareIcon opacity="1" />
                  </Link>
                </OverlayTrigger>
              ) : (
                <ShareIcon opacity="0.5" />
              )}
            </Box>
          </>
        );
      },
      center: true,
    },
  ];

  const sendReport = async ({ selectedItems, sendResponse, report_id, id }) => {
    try {
      setReportLoading(id);

      let reqData = {
        report_id,
      };

      if (sendResponse) {
        reqData.sendResponse = true;
        reqData.email = selectedItems;
      }

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}asset/send-web-security-report`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify(reqData),
      };
      setTimeout(async () => {
        try {
          let { data, message } = await axios.request(config);
          if (data === null) {
            ToastMessage.Error(message);
          } else if (data === "report sent successfully.") {
            ToastMessage.Success(data);
          } else {
            const immuniData = await axios.post(
              API_ROUTES.GET_IMMUNI_WEB_V2_DATA,
              { assets_id: params.id }
            );
            const responseData = common.decrypt(immuniData.data);
            const blob = await pdf(
              <PDFDocument
                onlineAssetData={assetsData}
                immuniWeb={responseData}
                userName={props.tested_by}
                testCount={testCount}
              />
            ).toBlob();
            saveAs(blob, `web-security-report.pdf`);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setReportLoading(false);
        }
      }, 500);
    } catch (error) {
      console.error(error);
      setReportLoading(false);
    }
  };

  const handleSaveChangesClick = (event) => {
    event.preventDefault();

    const selectedItems = choicesRef.current.getValue(true);
    if (selectedItems?.length === 0) {
      setError("Please enter email address.");
    } else {
      if (reportId) {
        sendReport({ selectedItems, sendResponse: true, report_id: reportId });
        handleCloseInput();
      }
    }
  };

  useEffect(() => {
    if (show) {
      const element = document.getElementById("choices-email-input");
      if (element) {
        const choicesInstance = new Choices(element, {
          removeItemButton: true,
          duplicateItemsAllowed: false,
          maxItemCount: 10,
          placeholderValue: "Enter email address",
          addItemFilter: (value) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (emailRegex.test(value)) {
              setError("");
              return true;
            } else {
              return false;
            }
          },
        });
        choicesRef.current = choicesInstance;
      }
    }
  }, [show]);

  return (
    <Box className="Websecurity-history">
      <ToastContainer />
      <Card>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Spinner height={100} width={100} />
          </div>
        ) : (
          <Card.Body>
            <DataTable
              // pointerOnHover
              columns={columns}
              data={securityHistory?.length > 0 ? securityHistory : []}
              pagination
              className="table-design"
            />
          </Card.Body>
        )}
      </Card>

      <Modal show={show} onHide={handleCloseInput} centered>
        <Modal.Body>
          <Form>
            <Box>
              <label htmlFor="choices-email-input">Email</label>
              <input
                id="choices-email-input"
                className="form-control"
                type="text"
                placeholder="Enter email address"
              />
            </Box>
            {error && <Box className="text-danger">{error}</Box>}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary" onClick={handleCloseInput}>
            Close
          </Button>
          <Button
            className="btn btn-dark"
            type="submit"
            onClick={handleSaveChangesClick}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </Box>
  );
};

export default WebsiteSecurityHistory;
