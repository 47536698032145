import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
import { Form, Label, FormFeedback, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Comments from "./Comments";
import TextEditor from "../../../Components/Common/TextEditor";
import SimpleBar from "simplebar-react";
import { getAuthToken } from "../../../Api/common";

const TicketComments = ({ currentTicket }) => {
  const token = getAuthToken();
  const navigate = useNavigate();
  let ticketId = currentTicket;

  const [ticketCommentsData, setTicketCommentsData] = useState([]);
  const [openCommentId, setOpenCommentId] = useState(null);

  const rootCommentArray = ticketCommentsData.filter(
    (comment) => comment.comment_id === null
  );

  const getNestedComments = (parentId) => {
    return ticketCommentsData.filter(
      (comment) => comment.comment_id === parentId
    );
  };

  useEffect(() => {
    loadComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTicket]);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      ticketComments: "",
    },

    validationSchema: Yup.object().shape({
      ticketComments: Yup.string().required("Please enter the comment."),
    }),

    onSubmit: (values) => {
      const { ticketComments } = values;
      const data = { ticketComments, ticketId };
      postComments(data);
    },
  });

  const postComments = async (commentsData) => {
    if (
      Object.keys(commentsData).includes("replyId") &&
      commentsData["replyId"]
    ) {
      commentsData["comment_id"] = commentsData["replyId"];
      commentsData["ticketComments"] = commentsData["replyComments"];
      delete commentsData["replyId"];
      delete commentsData["replyComments"];

      try {
        let url = `${process.env.REACT_APP_BASE_URL}Newticketing-system/add-ticket-comments`;
        let reqData = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(commentsData),
        };
        let result = await fetch(url, reqData);
        let response = await result.json();

        if (response.status === "success") {
          ToastMessage.Success(response.message);
          loadComments();
          formik.resetForm();
        } else if (response.status === "fail") {
          ToastMessage.Error(response.message);
          formik.resetForm();
        }
      } catch (error) {
        formik.resetForm();
        ToastMessage.Error(error.message);
        return null;
      }
    } else {
      try {
        let url = `${process.env.REACT_APP_BASE_URL}Newticketing-system/add-ticket-comments`;
        let reqData = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(commentsData),
        };
        let result = await fetch(url, reqData);
        let response = await result.json();

        if (response.status === "success") {
          ToastMessage.Success(response.message);
          loadComments();
          formik.resetForm();
        } else if (response.status === "fail") {
          ToastMessage.Error(response.message);
          formik.resetForm();
        }
      } catch (error) {
        formik.resetForm();
        ToastMessage.Error(error.message);
        return null;
      }
    }
  };

  const loadComments = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const ticketCommentsResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}Newticketing-system/ticket-comments?ticketId=${ticketId}`,
        config
      );

      if (ticketCommentsResponse.code === 403) {
        navigate("/auth-404-cover");
      }

      if (ticketCommentsResponse.status === "success") {
        setTicketCommentsData(ticketCommentsResponse.data);
      } else if (ticketCommentsResponse.status === "fail") {
        ToastMessage.Error(ticketCommentsResponse.message);
      }
    } catch (error) {
      return null;
    }
  };

  return (
    <>
      <h5 className="card-title mb-4">Comments</h5>

      {ticketCommentsData && ticketCommentsData.length > 0 ? (
        <SimpleBar
          className="px-4"
          style={{ maxHeight: "380px" }}
          autoHide={false}
        >
          {rootCommentArray.map((comment) => (
            <Comments
              key={comment.id}
              comment={comment}
              nestedComments={getNestedComments(comment.id)}
              postComments={postComments}
              ticketId={currentTicket}
              openCommentId={openCommentId}
              setOpenCommentId={setOpenCommentId}
              getNestedComments={getNestedComments} // Pass the function
              showReplyButton={true}
            />
          ))}
        </SimpleBar>
      ) : (
        <p>No comments found</p>
      )}

      <Form className="pt-4" onSubmit={formik.handleSubmit}>
        <Label htmlFor="comments" className="form-label">
          Leave a Comment
        </Label>
        <TextEditor
          value={formik.values.ticketComments}
          onChange={(data) => {
            formik.setFieldValue("ticketComments", data);
          }}
          // filename="ticket-comment-attachment"
          filename="ticket-comment-attechment"
          // apiUrl={`${process.env.REACT_APP_BASE_URL}Newticketing-system/ticket-comments/attachments`}
          apiUrl={`${process.env.REACT_APP_BASE_URL}Newticketing-system/ticket-comments/attechments`} 
        />
        {formik.errors.ticketComments && formik.touched.ticketComments && (
          <FormFeedback
            type="invalid"
            className="d-block"
            style={{ color: "red" }}
          >
            {formik.errors.ticketComments}
          </FormFeedback>
        )}
        <Button className="btn btn-primary mt-4" type="submit">
          Post Comment
        </Button>
      </Form>
    </>
  );
};

export default TicketComments;
