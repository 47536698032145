export const ONLINE_ASSETS = "Online Assets";
export const USERS = "Users";
export const DEPARTMENT_USERS = "Department Users";
export const DEVELOPER_PROFILE = "Developer Profile";
export const WEBSITE_GUIDELINE = "Website Guideline";
export const BRANDING_GUIDELINE = "Branding Guideline";
export const ANNOUNCEMENTS = "Announcements";
export const TICKETING_SYSTEM = "Ticketing System";
export const WEBSITE_STATISTICS = "Website Statistics";
export const ACCESS_RIGHTS = "Access Rights";
export const UPTIME_REPORT = "Uptime Report";
export const HIT_REPORT = "Hit Report";
export const BLOCKED_IP = "Blocked IP";
export const PERFORMANCE = "Performance";
export const WEB_SECURITY = "Web Security";

export const GENERAL_SETTING = "General Setting";
export const SMTP_MAIL_SETTING = "SMTP-MAIL Setting";
export const SEO_SETTING = "Seo Setting";
export const SECURITY_SETTING = "Security Setting";
export const CRON_SETTING = "Cron Setting";
export const OTHER_SETTING = "Other Setting";
export const API_KEY = "Api Key";
export const WEBSITE_CATEGORIES = "Website Category";
export const SKILLS = "Skills";
export const AUDIT_LOG = "Audit Log";
export const NOTIFICATION_SETTINGS = "Notification Setting";
export const MY_PACKAGE = "My Package";
