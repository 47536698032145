import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Select from "react-select";
import classnames from "classnames";
// import Select from "react-select";

import progileBg from "../../../../assets/images/profile-bg.jpg";
import { ToastMessage } from "../../../../Components/Common/ToastMessage";
import { ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import ProfileImage from "../../../../Components/Common/ProfileImage";
import { changePasswordReq } from "../../../../Api/MyProfile/PagesProfileSetting/PagesProfileSettingReq";
import { ChangePasswordRes } from "../../../../Api/MyProfile/PagesProfileSetting/PagesProfileSettingRes";
import { USERS } from "../../../../Components/Common/ModuleName";
import { useDispatch, useSelector } from "react-redux";
import { addUserData } from "../../../../store/userData/action";
import axios from "axios";
import { getAuthToken } from "../../../../Api/common";

const Settings = () => {
  const token = getAuthToken();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissionData = useSelector(state => state.userDetails.userPermission);
  const hasPermission = permissionData?.find(item => item.module_name === USERS);
  const [data, setData] = useState([]);

  const [activeTab, setActiveTab] = useState("1");
  // const [roleOptions, setRoleOptions] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedCoverImage, setSelectedCoverImage] = useState(null);

  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)
  const [websiteCategory , setWebsiteCategory] = useState([]);
  // const [departmentSelectOpt, setDepartmentSelectOpt] = useState([null]);
  const [errors, setErrors] = useState(null)
  const [success, setSuccess] = useState(null)
  // const [statusSelectOpt, setStatusSelectOpt] = useState({value: "Active",label: "Active"}); 


  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    getUserData();
    // getUserType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserData = async () => {
    let reqData = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    let result = await fetch(
      `${process.env.REACT_APP_BASE_URL}users-website/user-management-users/${params.id}`,
      reqData
    );
    let response = await result.json();
    if (response.status === "success") {
      setData(response.data);
    }
    if (response.status === "fail") {
      ToastMessage.Error("Error in fetching the data, Please try again later");
    }
  };

  const changePasswordValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },

    validationSchema: Yup.object({
      oldPassword: Yup.string().max(255).required("Old password is required."),
      newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("This field is required")
        .test('notSameAsOldPassword', 'New password must be different from the old password', function (value) {
          return value !== this.parent.oldPassword;
        }),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm Password Required"),
    }),

    onSubmit: (values) => {
      changePassword(values)
    },
  });

  const changePassword = async (values) => {
    try {
      const { url, reqData } = changePasswordReq(values);

      let result = await fetch(url, reqData);
      let response = await result.json();

      ChangePasswordRes(changePasswordValidation, response, setErrors, setSuccess, navigate, params.id);
    } catch (error) {
      return null;
    }
  };

  const validation = useFormik({
    
    enableReinitialize: true,

    initialValues: {
      fullName: data ? data.user_name : "",
      contactNo: data.contact_no,
      email: data.email,
      department: data.department_id,
      organization: data.organization,
      // role: data.role,
      organizationAddress: data.organization_address || "",
      status: data.status,
      description: data.description,
      user_image: data ? data.user_image : "",
      profile_picture: data ? data.profile_picture : "",
    },

    validationSchema: Yup.object({
      fullName: Yup.string().max(255).required("Please enter full name."),
      // contactNo: Yup.string().required("contact no. is required"),
      contactNo: Yup.string()
        .matches(/^(\(\d{3}\)[- .]?)?\d{3}[- .]?\d{4}|\d{10}$/, "Please enter valid phone number."),

      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Please enter email id."),
      department: Yup.string().max(255).required("Please enter department."),
      organization: Yup.string().max(255).required("Please enter organization."),
      // role: Yup.string().max(255).required("role is required"),
      organizationAddress: Yup.string()
        .max(255)
        .required("Please enter organization address."),
      status: Yup.string().max(255).required("Please enter status."),
      description: Yup.string().max(255).nullable(),
      user_image: Yup.mixed()
        .required('Please select the Image.'),
      profile_picture: Yup.mixed().required(
        "Please select the Cover Image."
      ),
    }),

    onSubmit: (values) => {
      updateUsers(values);
    },
  });

  const updateUsers = async (values) => {
    try {
      let data = new FormData();
      if (values.user_image && values.user_image.constructor === File) {
        data.append("user_image", values.user_image);
      }

      if (values.profile_picture && values.profile_picture.constructor === File) {
        data.append("profile_picture", values.profile_picture);
      }
      data.append("user_name", values.fullName);
      data.append("organization", values.organization);
      data.append("contact_no", values.contactNo);
      data.append("email", values.email);
      data.append("organization_address", values.organizationAddress);
      data.append("department_id", values.department);
      // data.append("role", values.role);
      data.append("status", values.status);
      data.append("description", values.description);

      let reqData = {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: data,
      };

      let result = await fetch(
        `${process.env.REACT_APP_BASE_URL}users-website/update-user-management-users/${params.id}`,
        reqData
      );
      let response = await result.json();
      if (response.status === "success") {
        ToastMessage.Success("Details has been updated successfully.");
        dispatch(addUserData(response.data));
        localStorage.setItem("user_name", values.fullName)
        setTimeout(() => {
          navigate("/pages-profile/" + params.id)
        }, 2000)
        getUserData();
      } else {
        getUserData();
        ToastMessage.Error("An error occurred. Please try again later.");
      }
    } catch (error) {
      return null;
    }
  }

  const getWebsiteData = useCallback(async (searchParams) => {
    try {
      // let params = searchParams || ""
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}websiteCategories/get-category`
      );

      if (response.status === "success") {
        setWebsiteCategory(response.data);
      } 
    } catch (error) {
      console.error('Error fetching website data:', error);
    }
  },  []);

  useEffect(() => {
    getWebsiteData();
  }, [getWebsiteData]);

  const departmentOptions = websiteCategory.map((d) => ({
    value: d.id,
    label: d.category,
  }));

  const options = [
    { value: "Active", label: "Active" },
    { value: "In-Active", label: "In-Active" },
  ];


  const handleChangeImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedFormats = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml"
      ];
      const validSize = file.size <= 1024 * 1024;

      if (!allowedFormats.includes(file.type)) {
        validation.setFieldError(
          "user_image",
          "Invalid file format. Only JPEG, JPG, PNG, and SVG files are allowed."
        );
        event.target.value = null;
      } else if (!validSize) {
        validation.setFieldError("user_image", "Failed to upload an image. The image maximum size is 1MB.");
        event.target.value = null;
      } else {
        validation.setFieldError("user_image", "");
        validation.setFieldValue("user_image", file);
        const imageUrl = URL.createObjectURL(file);
        setSelectedImage(imageUrl);
      }
    }
  };

  const handleChangeCoverImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedFormats = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml"
      ];
      const validSize = file.size <= 1024 * 1024;

      if (!allowedFormats.includes(file.type)) {
        validation.setFieldError(
          "profile_picture",
          "Invalid file format. Only JPEG, JPG, PNG, and SVG files are allowed."
        );
        event.target.value = null;
      } else if (!validSize) {
        validation.setFieldError("profile_picture", "Failed to upload an image. The image maximum size is 1MB.");
        event.target.value = null;
      } else {
        validation.setFieldError("profile_picture", "");
        validation.setFieldValue("profile_picture", file);
        const imageUrl = URL.createObjectURL(file);

        setSelectedCoverImage(imageUrl);
      }
    }
  };


  return (
    <React.Fragment>
      <ToastContainer closeButton={true} />
      <div className="page-content">
        <Container fluid>
          <div className="position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg profile-setting-img">
              {selectedCoverImage || data.profile_picture !== "null" ? (
                <img
                  name="profile_picture"
                  src={selectedCoverImage || `${process.env.REACT_APP_BASE_URL}${data.profile_picture}`}
                  className="profile-wid-img"
                  alt="user-profile"
                />
              ) : (
                <img
                  name="profile_picture"
                  src={progileBg}
                  className="profile-wid-img"
                  alt="user-profile"
                />
              )}
              <div className="overlay-content">
                <div className="text-end p-3">
                  <div className="p-0 ms-auto rounded-circle profile-photo-edit">
                    <Input
                      id="profile-cover-img-file-input"
                      type="file"
                      className="profile-foreground-img-file-input"
                      name="profile_picture"
                      accept="image/*"
                      onChange={(e) => {
                        handleChangeCoverImage(e);
                        validation.setFieldTouched(
                          "profile_picture",
                          true,
                          false
                        );
                      }}
                      onBlur={validation.handleBlur}
                    />
                    <Label
                      htmlFor="profile-cover-img-file-input"
                      className="profile-photo-edit btn btn-light"
                    >
                      <i className="ri-image-edit-line align-bottom me-1"></i>{" "}
                      Change Cover
                    </Label>
                  </div>
                </div>
                {validation.errors.profile_picture &&
                  validation.touched.profile_picture ? (
                  <span style={{ color: "red" }}>
                    {validation.errors.profile_picture}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <Row>
            <Col xxl={3}>
              <Card className="mt-n5">
                <CardBody className="p-4">
                  <div className="text-center">
                    <div className="profile-user position-relative d-inline-block mx-auto avatar-xl mb-4">
                      {selectedImage ? (
                        <img
                          name="user_image"
                          src={selectedImage}
                          className="avatar-xl rounded-circle flex-shrink-0 border img-thumbnail"
                          alt="user-profile"
                        />
                      ) : data.user_image !== "null" ?
                        (<>
                          <img
                            name="user_image"
                            src={`${process.env.REACT_APP_BASE_URL}${data.user_image}`}
                            // src={data.user_image}
                            className="avatar-xl rounded-circle flex-shrink-0 border img-thumbnail"
                            alt="user-profile"
                            height="100%"
                            width="100%"
                            fontSize="30px"
                          />
                        </>)
                        :
                        (
                          <ProfileImage username={data.user_name || "Demo User"} height="100%" width="100%" fontSize="30px" />
                        )}
                      <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                        <Input
                          id="profile-img-file-input"
                          type="file"
                          name="user_image"
                          accept="image/*"
                          onChange={(e) => {
                            handleChangeImage(e);
                            validation.setFieldTouched(
                              "user_image",
                              true,
                              false
                            );
                          }}
                          onBlur={validation.handleBlur}
                          className="profile-img-file-input"
                        />
                        <Label
                          htmlFor="profile-img-file-input"
                          className="profile-photo-edit avatar-xs"
                        >
                          <span className="avatar-title rounded-circle bg-light text-body">
                            <i className="ri-camera-fill"></i>
                          </span>
                        </Label>
                      </div>
                    </div>
                    {validation.errors.user_image &&
                      validation.touched.user_image ? (
                      <div className="d-block" style={{ color: "red" }}>
                        {validation.errors.user_image}
                      </div>
                    ) : null}
                    <h5 className="fs-16 mb-1">{data.user_name}</h5>
                    <p className="text-muted mb-0">{data.role}</p>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xxl={9}>
              <Card className="mt-xxl-n5">
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          tabChange("1");
                        }}
                        type="button"
                      >
                        <i className="fas fa-home"></i>
                        Personal Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          tabChange("2");
                        }}
                        type="button"
                      >
                        <i className="fas fa-home"></i>
                        Change Password
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Form onSubmit={validation.handleSubmit}>
                        <Row>
                          <Col lg={12}>
                            <div className="mb-3">
                              <Label >
                                Full Name *
                              </Label>
                              <Input
                                name="fullName"
                                type="text"
                                placeholder="Enter your firstname"
                                value={validation.values.fullName}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.errors.fullName &&
                                    validation.touched.fullName
                                    ? true
                                    : false
                                }
                              />
                              {validation.errors.fullName && validation.touched.fullName ? (<span style={{ color: "red" }}>{validation.errors.fullName} </span>) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Phone Number
                              </Label>
                              <Input
                                name="contactNo"
                                value={validation.values.contactNo}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                type="text"
                                placeholder="Enter your phone number"
                                invalid={
                                  validation.errors.contactNo &&
                                    validation.touched.contactNo
                                    ? true
                                    : false
                                }
                              />
                              {validation.errors.contactNo && validation.touched.contactNo ? (
                                <span style={{ color: "red" }}>{validation.errors.contactNo} </span>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="emailInput"
                                className="form-label"
                              >
                                Email Address *
                              </Label>
                              <Input
                                name="email"
                                value={validation.values.email}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                type="email"
                                placeholder="Enter your email"
                                invalid={
                                  validation.errors.email &&
                                    validation.touched.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.errors.email && validation.touched.email ? (<span style={{ color: "red" }}>{validation.errors.email} </span>) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="JoiningdatInput"
                                className="form-label"
                              >
                                Department
                              </Label>
                              {/* <Input
                                name="department"
                                value={validation.values.department}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                type="text"
                                placeholder="Enter your firstname"
                                invalid={
                                  validation.errors.department &&
                                    validation.touched.department
                                    ? true
                                    : false
                                }
                              /> */}
                              <Select
                                name="department_id"
                                options={departmentOptions}
                                isSearchable={true}
                                onChange={(option) => {
                                  validation.setFieldValue("department", option.value);
                                  // setDepartmentSelectOpt(option);
                                }}
                                // value={departmentSelectOpt}
                                value={departmentOptions.find((opt) => opt.value === validation.values.department)}
                                onBlur={validation.handleBlur}
                              />
                              {validation.errors.department && validation.touched.department ? (<span style={{ color: "red" }}>{validation.errors.department} </span>) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="firstnameInput"
                                className="form-label"
                              >
                                Organization *
                              </Label>
                              <Input
                                type="text"
                                placeholder="Enter your firstname"
                                name="organization"
                                value={validation.values.organization}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.errors.organization &&
                                    validation.touched.organization
                                    ? true
                                    : false
                                }
                              />
                              {validation.errors.organization && validation.touched.organization ? (<span style={{ color: "red" }}>{validation.errors.organization} </span>) : null}
                            </div>
                          </Col>
                          {/* <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="skillsInput"
                                className="form-label"
                              >
                                Role
                              </Label>
                              <Select
                                // isMulti
                                name="role"
                                value={{ value: validation.values.role, label: validation.values.role }}
                                onChange={(selectedOption) => {
                                  validation.setFieldValue("role", selectedOption.value);
                                }}
                                onBlur={validation.handleBlur}
                                options={roleOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                              />
                            </div>
                          </Col> */}
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="designationInput"
                                className="form-label"
                              >
                                organization Address *
                              </Label>
                              <Input
                                name="organizationAddress"
                                value={validation.values.organizationAddress}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.errors.organizationAddress &&
                                    validation.touched.organizationAddress
                                    ? true
                                    : false
                                }
                                type="text"
                                className="form-control"
                                id="designationInput"
                                placeholder="Designation"
                              />
                              {validation.errors.organizationAddress && validation.touched.organizationAddress ? (<span style={{ color: "red" }}>{validation.errors.organizationAddress} </span>) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="websiteInput1"
                                className="form-label"
                              >
                                status
                              </Label>
                              {/* <Input
                                name="status"
                                value={validation.values.status}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.errors.status &&
                                    validation.touched.status
                                    ? true
                                    : false
                                }
                                type="text"
                                className="form-control"
                                id="websiteInput1"
                                placeholder="www.example.com"
                              /> */}
                               <Select
                                options={options}
                                placeholder="Select an option"
                                isSearchable={true}
                                // value={statusSelectOpt}
                                value={options.find((opt) => opt.value === validation.values.status)}
                                onChange={(option) => {
                                  validation.setFieldValue("status", option.value);
                                  // setStatusSelectOpt(option);
                                }}
                              />
                              {validation.errors.status && validation.touched.status ? (<span style={{ color: "red" }}>{validation.errors.status} </span>) : null}
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="mb-3 pb-2">
                              <Label htmlFor="exampleFormControlTextarea" className="form-label">
                                Description
                              </Label>
                              <textarea
                                className="form-control"
                                id="exampleFormControlTextarea"
                                rows="3"
                                value={validation.values.description}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                name="description"
                              />
                              {validation.errors.description && validation.touched.description ? (
                                <span style={{ color: "red" }}>{validation.errors.description} </span>
                              ) : null}
                            </div>
                          </Col>
                          {hasPermission?.canModify !== null ? (
                            < Col lg={12}>
                              <div className="hstack gap-2 justify-content-end">
                                <button type="submit" className="btn btn-primary">
                                  Update
                                </button>
                                <Link to={`/pages-profile/${params.id}`} className="btn btn-secondary">
                                  Cancel
                                </Link>
                              </div>
                            </Col>
                          ) : ""}

                        </Row>
                      </Form>
                    </TabPane>

                    <TabPane tabId="2">
                      <Form onSubmit={changePasswordValidation.handleSubmit} >
                        <Row className="g-2">
                          <div className={!errors ? "d-none" : "alert alert-borderless alert-danger text-center mb-2 mx-2"} role="alert">
                            {errors && errors}
                          </div>
                          <div className={!success ? "d-none" : "alert alert-borderless alert-success text-center mb-2 mx-2"} role="alert">
                            {success && success}
                          </div>
                          <Col lg={4}>
                            <Label
                              htmlFor="oldpasswordInput"
                              className="form-label"
                            >
                              Old Password*
                            </Label>
                            <div className="position-relative auth-pass-inputgroup" >
                              <Input
                                type={showOldPassword ? "text" : "password"}
                                className="form-control pe-5 password-input"
                                id="oldpasswordInput"
                                placeholder="Enter current password"
                                {...changePasswordValidation.getFieldProps("oldPassword")}
                                invalid={
                                  changePasswordValidation.errors.oldPassword &&
                                    changePasswordValidation.touched.oldPassword
                                    ? true
                                    : false
                                }
                              />
                              {changePasswordValidation.touched.oldPassword &&
                                changePasswordValidation.errors.oldPassword ? (
                                <div style={{ color: "red" }} >{changePasswordValidation.errors.oldPassword}</div>
                              ) : null}
                              <Button
                                color="link"
                                onClick={() => setShowOldPassword(!showOldPassword)}
                                className="position-absolute end-0 top-0 text-decoration-none text-muted password-old"
                                id="password-old"
                              >
                                <i className="ri-eye-fill align-middle"></i>
                              </Button>
                            </div>
                          </Col>

                          <Col lg={4}>
                            <Label
                              htmlFor="newpasswordInput"
                              className="form-label"
                            >
                              New Password*
                            </Label>
                            <div className="position-relative auth-pass-inputgroup">
                              <Input
                                type={showNewPassword ? "text" : "password"}
                                className="form-control"
                                id="newpasswordInput"
                                placeholder="Enter new password"
                                {...changePasswordValidation.getFieldProps("newPassword")}
                                invalid={
                                  changePasswordValidation.errors.newPassword &&
                                    changePasswordValidation.touched.newPassword
                                    ? true
                                    : false
                                }
                              />
                              {changePasswordValidation.touched.newPassword &&
                                changePasswordValidation.errors.newPassword ? (
                                <div style={{ color: "red" }} >{changePasswordValidation.errors.newPassword}</div>
                              ) : null}
                              <Button
                                color="link"
                                onClick={() => setShowNewPassword(!showNewPassword)}
                                className="position-absolute end-0 top-0 text-decoration-none text-muted password-new"
                                id="password-new"
                              >
                                <i className="ri-eye-fill align-middle"></i>
                              </Button>
                            </div>
                          </Col>

                          <Col lg={4}>
                            <Label
                              htmlFor="confirmpasswordInput"
                              className="form-label"
                            >
                              Confirm Password*
                            </Label>
                            <div className="position-relative auth-pass-inputgroup">
                              <Input
                                type={showConfirmNewPassword ? "text" : "password"}
                                className="form-control"
                                id="confirmpasswordInput"
                                placeholder="Confirm password"
                                name="confirmNewPassword"
                                {...changePasswordValidation.getFieldProps("confirmNewPassword")}
                                invalid={
                                  changePasswordValidation.errors.confirmNewPassword &&
                                    changePasswordValidation.touched.confirmNewPassword
                                    ? true
                                    : false
                                }
                              />
                              {changePasswordValidation.touched.confirmNewPassword &&
                                changePasswordValidation.errors.confirmNewPassword ? (
                                <div style={{ color: "red" }} >{changePasswordValidation.errors.confirmNewPassword}</div>
                              ) : null}
                              <Button
                                color="link"
                                onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                                className="position-absolute end-0 top-0 text-decoration-none text-muted password-confirm"
                                id="password-confirm"
                              >
                                <i className="ri-eye-fill align-middle"></i>
                              </Button>
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div className="text-end">
                              <button type="submit" className="btn btn-success">
                                Change Password
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default Settings;
