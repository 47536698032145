import React, { useState } from "react";
import {
  Box,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  Skeleton,
  Switch,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";

const getMsOrSec = (value) =>
  value < 1000 ? value + "ms" : (value / 1000).toFixed(1) + "s";

const AndroidSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  position: "relative",
  "&.MuiSwitch-root": {
    width: 72,
    "&:has(.MuiSwitch-switchBase.Mui-checked)": {
      "& .MuiSwitch-track::after": {
        display: "none",
      },
      "& .MuiSwitch-track::before": {
        display: "block",
      },
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    background: "#E3EAEE",
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 27,
      height: 23,
      color: "#1A2232",
      fontSize: "14px",
      fontWeight: 500,
    },
    "&::before": {
      content: '"ON"',
      left: 12,
      display: "none",
    },
    "&::after": {
      content: '"OFF"',
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#9DA3AB",
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      textAlign: "center",
      fontSize: "0.75rem",
      color: theme.palette.text.primary,
      pointerEvents: "none",
    },
    "&.Mui-checked::after": {
      content: '""',
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    transform: "translateX(32px)",
  },
  "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
    backgroundColor: "#1e2638",
    opacity: 1,
    "&::before": {
      color: "#fff",
    },
  },
}));

const PerformanceTab = (props) => {
  // const [scores, setGtmetrixData] = useState("");
  // const [typedata, setType] = useState("1");
  // const [timeFilter, setTimeFilter] = useState("year");

  const MetricsData = [
    {
      id: 1,
      title: "First Contentful Paint",
      value:
        props.scores?.first_contentful_paint !== null &&
        props.scores?.first_contentful_paint !== undefined
          ? `${getMsOrSec(props.scores.first_contentful_paint)}`
          : "-",
      Toptag: "Longer than recommended",
      leftCurveBg: "#2EBBF6",
      cardStatus: "yellow-card",
      description:
        "How quickly content like text or images are painted onto your page. A good user experience is 0.9s or less.",
    },
    {
      id: 2,
      title: "Time to Interactive",
      value:
        props.scores?.time_to_interactive !== null &&
        props.scores?.time_to_interactive !== undefined
          ? `${getMsOrSec(props.scores.time_to_interactive)}`
          : "-",
      Toptag: "Much longer than recommended",
      leftCurveBg: "#B06DC9",
      cardStatus: "red-card",
      description:
        "How long it takes for your page to become fully interactive. A good user experience is 2.5s or less.",
    },
    {
      id: 3,
      title: "Speed Index",
      value:
        props.scores?.speed_index !== null &&
        props.scores?.speed_index !== undefined
          ? `${getMsOrSec(props.scores.speed_index)}`
          : "-",
      Toptag: "Much longer than recommended",
      leftCurveBg: "#F466A6",
      cardStatus: "red-card",
      description:
        "How quickly the contents of your page are visibly populated. A good user experience is 1.3s or less.",
    },
    {
      id: 4,
      title: "Total Blocking Time",
      value:
        props.scores?.total_blocking_time !== null &&
        props.scores?.total_blocking_time !== undefined
          ? `${getMsOrSec(props.scores.total_blocking_time)}`
          : "-",
      Toptag: "longer than recommended",
      leftCurveBg: "#7E5D8A",
      cardStatus: "yellow-card",
      description:
        "How much time is blocked by scripts during your page loading process. A good user experience is 150ms or less.",
    },
    {
      id: 5,
      title: "Largest Contentful Paint",
      value:
        props.scores?.largest_contentful_paint !== null &&
        props.scores?.largest_contentful_paint !== undefined
          ? `${getMsOrSec(props.scores.largest_contentful_paint)}`
          : "-",
      Toptag: "Longer than recommended",
      leftCurveBg: "#417BE7",
      cardStatus: "green-card",
      description:
        "How long it takes for the largest element of content (i.e., a hero image) to be painted on your page. A good user experience is 1.2s or less. ",
    },
    // {
    //   id: 6,
    //   title: "Cumulative Layout Shift",
    //   value:
    //     props.scores?.cumulative_layout_shift !== undefined &&
    //     props.scores?.cumulative_layout_shift !== null
    //       ? // ? `${parseFloat(props.scores.cumulative_layout_shift).toFixed(3)}s`
    //         `${parseFloat(props.scores.cumulative_layout_shift).toFixed(1)}`
    //       : "-",
    //   Toptag: "Much longer than recommended",
    //   leftCurveBg: "#4CC29D",
    //   cardStatus: "red-card",
    //   description:
    //     "How much your page's layout shifts as it loads. A good user experience is a score of 0.1 or less.",
    // },
    {
      id: 6,
      title: "Cumulative Layout Shift",
      value:
        props.scores?.cumulative_layout_shift !== undefined &&
        props.scores?.cumulative_layout_shift !== null
          ? parseFloat(props.scores.cumulative_layout_shift) < 0.1
            ? "0" // Display 0 if the value is less than 0.1
            : `${parseFloat(props.scores.cumulative_layout_shift).toFixed(1)}` // Otherwise, round to 1 decimal
          : "-", // Display "-" if the value is undefined or null
      Toptag: "Much longer than recommended",
      leftCurveBg: "#4CC29D",
      cardStatus: "red-card",
      description:
        "How much your page's layout shifts as it loads. A good user experience is a score of 0.1 or less.",
    },
  ];
  const TimingData = [
    {
      id: 1,
      title: "Redirect Duration",
      value:
        props.scores?.redirect_duration !== null &&
        props.scores?.redirect_duration !== undefined
          ? `${getMsOrSec(props.scores.redirect_duration)}`
          : "-",
      leftCurveBg: "#D3D8DD",
      tooltip:
        "This is the time spent redirecting URLs before the final HTML page is loaded.",
    },
    {
      id: 2,
      title: "Connection Duration",
      value:
        props.scores?.connect_duration !== null &&
        props.scores?.connect_duration !== undefined
          ? `${getMsOrSec(props.scores.connect_duration)}`
          : "-",
      leftCurveBg: "#D9D9D9",
      tooltip:
        "This is the time spent connecting to the server, after all redirects, to make the request to the page.",
    },
    {
      id: 3,
      title: "Backend Duration",
      value:
        props.scores?.backend_duration !== null &&
        props.scores?.backend_duration !== undefined
          ? `${getMsOrSec(props.scores.backend_duration)}`
          : "-",
      leftCurveBg: "#BEBAB9",
      tooltip:
        "This is the time it takes the server to generate the intial response.",
    },
    {
      id: 4,
      title: "Time to First Byte (TTFB)",
      value:
        props.scores?.time_to_first_byte !== null &&
        props.scores?.time_to_first_byte !== undefined
          ? `${getMsOrSec(props.scores.time_to_first_byte)}`
          : "-",
      leftCurveBg: "#8A98C1",
      tooltip:
        "This is the total amount of time from the start of the request to when we receive the first byte of the response.",
    },
    {
      id: 5,
      title: "DOM Interactive Time",
      value:
        props.scores?.dom_interactive_time !== null &&
        props.scores?.dom_interactive_time !== undefined
          ? `${getMsOrSec(props.scores.dom_interactive_time)}`
          : "-",
      leftCurveBg: "#1A2232",
      tooltip:
        "This timing is when the browser has finished loading and parsing HTML, and the DOM has been built.",
    },
    {
      id: 6,
      title: "First Paint",
      value:
        props.scores?.first_paint_time !== null &&
        props.scores?.first_paint_time !== undefined
          ? `${getMsOrSec(props.scores.first_paint_time)}`
          : "-",
      leftCurveBg: "#506791",
      tooltip:
        "This timing is the first point at which the browser does any sort of rendering on the page.",
    },
    {
      id: 7,
      title: "DOM Content Loaded Time",
      value:
        props.scores?.dom_content_loaded_time !== null &&
        props.scores?.dom_content_loaded_time !== undefined
          ? `${getMsOrSec(props.scores.dom_content_loaded_time)}`
          : "-",
      leftCurveBg: "#408483",
      tooltip:
        "This is the time at which the DOM is ready and there are no stylesheets blocking JavaScript execution.",
    },
    {
      id: 8,
      title: "Onload Time",
      value:
        props.scores?.onload_time !== null &&
        props.scores?.onload_time !== undefined
          ? `${getMsOrSec(props.scores.onload_time)}`
          : "-",
      leftCurveBg: "#A85C84",
      tooltip:
        "This timing occurs when the processing of the page is complete and all the page resources have finished downloading.",
    },
    {
      id: 9,
      title: "Fully Loaded Time",
      value:
        props.scores?.fully_loaded_time !== null &&
        props.scores?.fully_loaded_time !== undefined
          ? `${getMsOrSec(props.scores.fully_loaded_time)}`
          : "-",
      leftCurveBg: "#B22F69",
      tooltip:
        "This is the maximum time after FCP and Onload have triggered, and there has been network and CPU idle (5.25 s each) after the last request has been captured.",
    },
  ];

  const [showDescription, setShowDescription] = useState(false);

  const handleSwitchChange = () => {
    setShowDescription(!showDescription);
  };

  const renderMetricsCard = MetricsData.map((data) => {
    const { id, title, value, Toptag, leftCurveBg, cardStatus, description } =
      data;
    return (
      <Grid item xs={12} lg={6} key={id}>
        {props.dataLoading ? (
          <>
            <Skeleton
              variant="text"
              width={300}
              height={100}
              style={{ background: leftCurveBg, borderRadius: "20px" }}
            />
          </>
        ) : (
          <>
            <Box className={`matrics-card ${cardStatus}`}>
              <Box
                className="left-box"
                sx={{
                  "&::before": {
                    background: leftCurveBg,
                  },
                }}
              >
                <p className="title">
                  {title}

                  {/* <InterrogationIcon /> */}
                </p>
                {showDescription && (
                  <p className="description">{description}</p>
                )}
                <Box className="curve-side"></Box>
              </Box>
              <Box className="right-box">
                <p className="value">{value}</p>
                <Tooltip title={Toptag} placement="top">
                  <Chip label={Toptag} className="top-tag" />
                </Tooltip>
              </Box>
            </Box>
          </>
        )}
      </Grid>
    );
  });
  const renderTimingCard = TimingData.map((data) => {
    const { id, title, value, tooltip, leftCurveBg, cardStatus } = data;

    return (
      <Grid item xs={12} lg={6} xl={4} key={id}>
        {props.dataLoading ? (
          <>
            <Skeleton
              variant="text"
              width={300}
              height={70}
              style={{ background: leftCurveBg, borderRadius: "20px" }}
            />
          </>
        ) : (
          <>
            <Box className={`timing-card ${cardStatus}`}>
              <Box
                className="left-box"
                sx={{
                  "&::before": {
                    background: leftCurveBg,
                  },
                }}
              >
                <p className="title">
                  {title}{" "}
                  <Tooltip title={tooltip} arrow>
                    <HelpRoundedIcon />
                  </Tooltip>
                </p>

                <Box className="curve-side"></Box>
              </Box>
              <Box className="right-box">
                <p className="value">{value}</p>
              </Box>
            </Box>
          </>
        )}
      </Grid>
    );
  });
  return (
    <Box>
      <Box>
        <Grid container alignItems="end" className="mb-5">
          <Grid item xs={12} sm={8} lg={6}>
            <h3>Performance Metrics</h3>
            <p>
              The following metrics are generated using Lighthouse Performance
              data.
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            lg={6}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <FormControlLabel
              value="Metric details"
              // control={<AndroidSwitch />}
              control={
                <AndroidSwitch
                  checked={showDescription}
                  onChange={handleSwitchChange}
                />
              }
              label="Metric details"
              labelPlacement="start"
            />
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 4, xl: 8 }}>
          {renderMetricsCard}
        </Grid>
      </Box>
      <Divider sx={{ margin: "70px 0", border: "1px solid #E4E8EB" }} />

      <Box>
        <h3>Browser Timings</h3>
        <p className="mb-5">
          These timings are milestones reported by the browser.
        </p>
        <Grid container spacing={{ xs: 2, sm: 4 }}>
          {renderTimingCard}
        </Grid>
      </Box>
    </Box>
  );
};

export default PerformanceTab;
