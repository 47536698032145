import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Card, Col, Row } from "react-bootstrap";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
import TicketAttechments from "./TicketAttechments";
import TicketComments from "../TicketComments/TicketComments";
import { getResourceById } from "../../../helpers/Help/CommomApi";
import Skeleton from "@mui/material/Skeleton";
import { Box, Grid } from "@mui/material";
import { WebSecuTabStyle } from "../../NewDashboard/constant";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Tab from "@mui/material/Tab";
import { socket } from "../../../Components/Common/socket";
import common from "../../../Components/Common/common";
import CONSTANTS from "./Constant";
import AssignedMemberModal from "./AssignedMemberModal";
import { getAuthToken, getUserId } from "../../../Api/common";

const TicketDetail = () => {
  const token = getAuthToken();
  const userid = getUserId();
  const { TimeTrackingCard, TicketStatusCard, AssignedToCard } = CONSTANTS;

  const navigate = useNavigate();
  const params = useParams();

  const [data, setData] = useState([]);
  const [departmentUsersDataForAssgined, setDepartmentUsersDataForAssgined] =
    useState([]);
  const [assignedMembersToTicket, setAssignedMembersToTicket] = useState([]);
  const [membersToBeAssginedIDs, setMembersToBeAssginedIDs] = useState([]);
  const [membersToBeAssgined, setMembersToBeAssgined] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [assignedMembersModalShow, setAssignedMembersModalShow] =
    useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [timeDiff, setTimeDiff] = useState("");
  const [isStopLoading, setIsStopLoading] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [userIdOfTicketCreator, setUserIdOfTicketCreator] = useState("");
  const [assignedToDeleteButtonStatus, setAssignedToDeleteButtonStatus] =
    useState(false);

  useEffect(() => {
    getTicketData();
    getDepartmentUsers();
    getCheckTicketAssignedStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAssignedMembersToTicket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Latest Second

  useEffect(() => {
    const formatTimeDiff = (timeDifference) => {
      const day = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );

      return `${day} day${day !== 1 ? "s" : ""} ${hours} hour${
        hours !== 1 ? "s" : ""
      } ${minutes} minute${minutes !== 1 ? "s" : ""}`;
    };

    const dateTimeDiff = () => {
      let timeDifference;

      if (data.status === "pending" || data.status === "active") {
        if (data.reopen_time) {
          timeDifference =
            new Date(data.reopen_time) - new Date(data.createdAt);
        } else {
          timeDifference = new Date() - new Date(data.createdAt);
        }
      } else if (data.status === "closed" && data.close_time) {
        if (data.close_time) {
          timeDifference = new Date(data.close_time) - new Date(data.createdAt);
        } else {
          timeDifference = new Date(data.close_time) - new Date(data.createdAt);
        }
      } else if (data.status === "hold" && data.hold_time) {
        timeDifference = new Date(data.hold_time) - new Date(data.createdAt);
      } else {
        timeDifference = 0;
      }

      setTimeDiff(formatTimeDiff(timeDifference));
    };

    // Determine whether to use setInterval based on status
    if (data.status === "pending" || data.status === "active") {
      const intervalId = setInterval(dateTimeDiff, 1000);

      return () => {
        clearInterval(intervalId);
      };
    } else {
      // For closed or hold status, calculate time difference once
      dateTimeDiff();
    }
  }, [data]);

  const handleAssignedMembersModalClose = () => {
    setAssignedMembersModalShow(false);
    setSearchQuery("");
    setMembersToBeAssgined(membersToBeAssginedIDs);
  };

  const handleAssignedMembers = (memberID) => {
    if (membersToBeAssgined.includes(memberID)) {
      setMembersToBeAssgined((prevData) =>
        prevData.filter((id) => id !== memberID)
      );
    } else {
      setMembersToBeAssgined((prev) => [...prev, memberID]);
    }
  };

  const assginedMemebers = async () => {
    try {
      let body = {};
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      if (data && data.ticket_id) {
        body.ticketId = parseInt(data.ticket_id);
      }

      body.membersAssgined = membersToBeAssgined.join(",");

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}Newticketing-system/assgined-memebers`,
        body,
        config
      );

      if (response.code === 403) {
        navigate("/auth-404-cover");
      }

      if (response.status === "success") {
        getAssignedMembersToTicket();
        ToastMessage.Success(response.message);
        socket.emit("assignTicket");
      }

      if (response.status === "fail") {
        ToastMessage.Error(response.message);
      }
    } catch (error) {
      ToastMessage.Error("Something went wrong");

      return null;
    }
  };

  const deleteAssignedMember = async (userId) => {
    try {
      let body = {};
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      if (data && data.ticket_id) {
        body.ticketId = parseInt(data.ticket_id);
      }
      if (data && data.ticket_id) {
        body.memberToDelete = parseInt(userId);
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}Newticketing-system/delete-assgined-memebers`,
        body,
        config
      );
      if (response.code === 403) {
        navigate("/auth-404-cover");
      }

      if (response.status === "success") {
        getAssignedMembersToTicket();
        ToastMessage.Success(response.data);
      }

      if (response.status === "fail") {
        ToastMessage.Error(response.message);
      }
    } catch (error) {
      ToastMessage.Error("Something went wrong");
      return null;
    }
  };

  const getAssignedMembersToTicket = async () => {
    try {
      let AssignedMembersToTicketResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}Newticketing-system/ticket-assgined-memebers-list?ticketID=${params.id}`
      );

      const decryptedData = common.decrypt(
        AssignedMembersToTicketResponse.data
      );
      AssignedMembersToTicketResponse.data = decryptedData;

      if (AssignedMembersToTicketResponse.code === 403) {
        navigate("/auth-404-cover");
      }

      if (AssignedMembersToTicketResponse.status === "success") {
        setAssignedMembersToTicket(AssignedMembersToTicketResponse.data);

        let assignedMembersData = AssignedMembersToTicketResponse.data.map(
          (assignedMember) => assignedMember.id
        );

        setMembersToBeAssgined(assignedMembersData);
        setMembersToBeAssginedIDs(assignedMembersData);
      }

      if (AssignedMembersToTicketResponse.status === "fail") {
        // ToastMessage.Error(AssignedMembersToTicketResponse.message);
        return;
      }
    } catch (error) {
      return null;
    }
  };

  const getCheckTicketAssignedStatus = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}Newticketing-system/check-ticket-assigned-to?ticketID=${params.id}&userid=${userid}`
      );
      if (response.status === "success")
        setAssignedToDeleteButtonStatus(response.data?.editable);
    } catch (error) {
      console.error(error.message);
    }
  };

  const getDepartmentUsers = async () => {
    try {
      setIsLoading(true);
      getResourceById("", true, params.id)
        .then((response) => {
          const decryptedData = common.decrypt(response.data);
          response.data = decryptedData;

          if (response.data.code === 403) {
            navigate("/auth-404-cover");
          }
          // setDepartmentUsersData(response.data.data);

          setDepartmentUsersDataForAssgined(response.data);
        })
        .catch((error) => {
          return null;
        });
      setIsLoading(false);
    } catch (error) {
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const getTicketData = async () => {
    try {
      setDataLoading(true);

      let response = await axios.get(
        `Newticketing-system/get-tickets?searchFilter=${params.id}`
      );

      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;

      if (response.code === 401) {
        navigate("/auth-401-cover");
      }

      if (response.status === "success" && response.data) {
        setData(response.data && response.data[0]);
        setUserIdOfTicketCreator(response.data.user_id);
        setDataLoading(false);
      } else {
        return null;
      }
    } catch (error) {
      setDataLoading(false);
      return null;
    }
  };

  const updateSingleTicketStatus = async (newStatus) => {
    setIsStopLoading(true);
    try {
      let ticketID = params.id;
      let result = await axios.put(
        `${process.env.REACT_APP_BASE_URL}Newticketing-system/ticketstatus/${ticketID}`,
        { status: newStatus.toLowerCase() },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.code === 403) {
        navigate("/auth-404-cover");
      }

      if (result.status === "success") {
        setIsStopLoading(false);
        ToastMessage.Success(result.message);
        getTicketData();
        socket.emit("updateTicketStatus");
      }
      if (result.status === "fail") {
        setIsStopLoading(false);

        ToastMessage.Error("An error occurred. Please try again later.");
      }
    } catch (error) {
      ToastMessage.Error(error);
    }
  };

  useEffect(() => {
    if (data?.image) {
      const originalName = data?.original_name?.split("/").pop();
      const imageName = data?.image?.split("/").pop();
      const [name, type] = imageName?.split(".");
      setAttachments([{ name, type, imageName, originalName }]);
    }
  }, [data]);

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Box className="row g-4">
        <Box className="col-lg-12 col-xl-4 col-xxxl-3">
          <Row className="g-4">
            <Col md={6} xl={12} xxl={12}>
              <TimeTrackingCard
                data={data}
                dataLoading={dataLoading}
                timeDiff={timeDiff}
                updateSingleTicketStatus={updateSingleTicketStatus}
                isStopLoading={isStopLoading}
              />
            </Col>

            <Col md={6} xl={12} xxl={12}>
              <TicketStatusCard
                data={data}
                dataLoading={dataLoading}
                updateSingleTicketStatus={updateSingleTicketStatus}
              />
            </Col>

            <Col md={12} lg={12} xl={12} xxl={12}>
              <AssignedToCard
                userIdOfTicketCreator={userIdOfTicketCreator}
                assignedToDeleteButtonStatus={assignedToDeleteButtonStatus}
                setAssignedMembersModalShow={setAssignedMembersModalShow}
                isLoading={isLoading}
                assignedMembersToTicket={assignedMembersToTicket}
                deleteAssignedMember={deleteAssignedMember}
              />
            </Col>
          </Row>
        </Box>

        <Box className="col-lg-12 col-xl-8 col-xxxl-9">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box>
                <h3 className="fw-bold">Summary</h3>
                {dataLoading ? (
                  <>
                    <Skeleton variant="text" width={700} height={30} />
                    <Skeleton variant="text" width={700} height={30} />
                  </>
                ) : (
                  <p className="mb-0">{data.description}</p>
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <TabContext value={value}>
                  <Box>
                    <TabList
                      onChange={handleChange}
                      variant="fullWidth"
                      sx={WebSecuTabStyle}
                    >
                      <Tab label="Comments" value="1" />
                      <Tab label="Attachment File" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <TicketComments currentTicket={parseInt(params.id)} />
                  </TabPanel>
                  <TabPanel value="2">
                    <TicketAttechments attachments={attachments} />
                  </TabPanel>
                </TabContext>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <AssignedMemberModal
        assignedMembersModalShow={assignedMembersModalShow}
        handleAssignedMembersModalClose={handleAssignedMembersModalClose}
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        assignedMembersToTicket={assignedMembersToTicket}
        departmentUsersDataForAssgined={departmentUsersDataForAssgined}
        membersToBeAssgined={membersToBeAssgined}
        handleAssignedMembers={handleAssignedMembers}
        assginedMemebers={assginedMemebers}
        setAssignedMembersModalShow={setAssignedMembersModalShow}
        setMembersToBeAssgined={setMembersToBeAssgined}
        membersToBeAssginedIDs={membersToBeAssginedIDs}
      />
    </React.Fragment>
  );
};

export default TicketDetail;
