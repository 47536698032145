import React from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

//Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/index";

//routes
import { authProtectedRoutes, publicRoutes, accessRoute } from "./allRoutes";
import { AuthProtected } from "./AuthProtected";

import { ToastContainer } from "react-toastify";
import { useState } from "react";

import axios from "axios";
import { detect } from "detect-browser";
import { useEffect } from "react";
import { PrivateRoute } from "./AuthProtected";
import { setAuthorization } from "../helpers/api_helper";
import { ToastMessage } from "../Components/Common/ToastMessage";
import { Spinner } from "react-bootstrap";
import common from "../Components/Common/common";
import { getAuthToken } from "../Api/common";

const Index = () => {
    const [ok, setOk] = useState(false);
    const [loading, setLoading] = useState(false);
    const browser = detect();
    const token = getAuthToken();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const authCheck = async () => {
            setLoading(true);
            try {
                const statusCheckResponse = await fetch(
                    `${process.env.REACT_APP_BASE_URL}user/check-status`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                let statusResponse = await statusCheckResponse.json();
                if (statusResponse?.status === false) {
                    setOk(false);
                    localStorage.clear();
                    ToastMessage.Error(
                        `Your account has been ${
                            statusResponse?.reason === "deactivated"
                                ? "deactivated"
                                : "deleted"
                        }.\nPlease contact admin testbynetclues@gmail.com`
                    );
                    throw new Error("Something went wrong");
                }

                const browserName = browser.name;

                const responseIP = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}IpAddress/get`
                );
                const ip = common.decrypt(responseIP.data);

                const getBlockedIps = async () => {
                    let response = await axios.post(
                        `${process.env.REACT_APP_BASE_URL}ip/get-blockedIp`
                    );
                    response.data = common.decrypt(response.data);
                    const blockedIpsdata = await response?.data?.rows;
                    return blockedIpsdata;
                };

                const blockedIpFilterData = await getBlockedIps();

                const hasMatchingItem = blockedIpFilterData?.some(
                    (item) =>
                        item.ip_address === ip && item.login_access === "N"
                );

                const blockedIpstable = blockedIpFilterData?.find(
                    (item) =>
                        item.ip_address === ip && item.login_access === "N"
                );

                const blockedIpbyLogin = blockedIpstable?.ip_address;

                const getSettingData = async () => {
                    let { data } = await axios.get(
                        `${process.env.REACT_APP_BASE_URL}settings/get-settings`
                    );
                    const decryptedData = common.decrypt(data);
                    data = decryptedData;
                    const settingData = await data?.rows;
                    const filterData = settingData.filter(
                        (item) =>
                            item.settings_category === "general" ||
                            item.settings_category === "security"
                    );
                    return filterData;
                };

                const settingFilter = await getSettingData();

                const settingBlockedIp = settingFilter?.find(
                    (item) => item.field_name === "BLOCKED_IP"
                ).field_value;

                const settingLimitesAccessIp = settingFilter?.find(
                    (item) => item.field_name === "LIMITED_POWERPANEL_ACCESS"
                );

                const parseLimitedAccess =
                    settingLimitesAccessIp?.field_value &&
                    settingLimitesAccessIp?.field_value;

                const res = await fetch(
                    `${process.env.REACT_APP_BASE_URL}user/token-verify?blockedIpbyLogin=${blockedIpbyLogin}&settingBlockedIps=${settingBlockedIp}&parseLimitedAccess=${parseLimitedAccess}`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({ ip, browserName }),
                    }
                );

                const response = await res.json();
                setAuthorization(token);

                if (
                    response?.status === true &&
                    !hasMatchingItem &&
                    settingBlockedIp !== ip
                ) {
                    setOk(true);
                } else {
                    setOk(false);
                    localStorage.clear();
                    // ToastMessage.Error(response.message);
                }
            } catch (error) {
                setOk(false);
            } finally {
                setLoading(false);
            }
        };

        if (token) {
            authCheck();
        } else {
            setOk(false);
            setLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, navigate, location.pathname]);

    useEffect(() => {
        if (
            ok &&
            token &&
            (location.pathname === "/" || location.pathname === "/login")
        ) {
            navigate("/dashboard");
        }
    }, [ok, token, location.pathname, navigate]);

    return (
        <React.Fragment>
            {loading && !ok ? (
                <>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "2px",
                            marginTop: "430px",
                        }}
                    >
                        <Spinner />
                    </div>
                </>
            ) : (
                <Routes>
                    <Route>
                        {!ok &&
                            publicRoutes.map((route, idx) => (
                                <Route
                                    path={route.path}
                                    element={
                                        <NonAuthLayout>
                                            {route.component}
                                        </NonAuthLayout>
                                    }
                                    key={idx}
                                    exact={true}
                                />
                            ))}
                    </Route>

                    <Route element={<PrivateRoute ok={ok} />}>
                        {authProtectedRoutes.map((route, idx) => (
                            <Route
                                path={route.path}
                                element={
                                    <AuthProtected
                                        moduleCode={route.moduleCode}
                                        operation={route.operation}
                                    >
                                        <VerticalLayout>
                                            {route.component}
                                        </VerticalLayout>
                                    </AuthProtected>
                                }
                                key={idx}
                                exact={true}
                            />
                        ))}
                    </Route>

                    <Route>
                        {accessRoute.map((route, idx) => (
                            <Route
                                path={route.path}
                                element={
                                    <NonAuthLayout>
                                        {route.component}
                                    </NonAuthLayout>
                                }
                                key={idx}
                                exact={true}
                            />
                        ))}
                    </Route>
                </Routes>
            )}

            <ToastContainer closeButton={true} />
        </React.Fragment>
    );
};

export default Index;
