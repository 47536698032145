module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },

  facebook: {
    APP_ID: "",
  },

  api: {
    API_URL: process.env.REACT_APP_BASE_URL,
  },

  CRYPTO_TOKEN_SECRET_KEY: "CONTROLBASE_IS_THE_KEY",
};